/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';


/**
 * Used for the basic page layout.
 */
import {
    Checkbox,
    CollaboratingGroupContext,
    CrfCondition,
    CrfForm,
    Field,
    FieldGroup,
    FormBreadcrumbs,
    FormLabel,
    GetFieldLookupItem,
    ICrfConditionParameters,
    IStaticGridCellProps,
    IStaticGridHeaderProps,
    Input,
    InstitutionContext,
    KeyboardDatePicker,
    MasterGroupContext,
    Numeric,
    PatientContext,
    RouteLoading,
    Select,
    StaticGrid,
    StaticGridRow,
    Text,
    ToggleButtonGroup,
    useFormActions,
    useFormState
} from '@ngt/opms';

import { Permission, usePermissionsByIds } from '@ngt/opms-bctapi';

import { RequestState } from '@ngt/request-utilities';

import { Chip, InputAdornment, Typography, makeStyles } from '@material-ui/core';


/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import { NumberFormatValues } from 'react-number-format';
import * as Dtos from '../../api/dtos';
import SourceDocuments from '../../components/SourceDocuments';


/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface ILaboratoryAssessmentsProps {
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    subHeading: {
        padding: theme.spacing(3, 3, 3, 3)
    },
    instruction: {
        padding: theme.spacing(0, 3, 3),
        fontSize: '1rem'
    }
}));

/*
 * ----------------------------------------------------------------------------------
 * Functions
 * ----------------------------------------------------------------------------------
 */

// const isHepatitisDateNotSameAsAbove = ({ lookups, formState }: ICrfConditionParameters<Dtos.LaboratoryAssessments>) => !formState?.values.hepatitisSameAsAbove;
// const isUrinalysisDateNotSameAsAbove = ({ lookups, formState }: ICrfConditionParameters<Dtos.LaboratoryAssessments>) => !formState?.values.urinalysisSameAsAbove;
// const isBiochemistryDateNotSameAsAbove = ({ lookups, formState }: ICrfConditionParameters<Dtos.LaboratoryAssessments>) => !formState?.values.biochemistrySameAsAbove;
// const isCoagulationNotSameAsAbove = ({ lookups, formState }: ICrfConditionParameters<Dtos.LaboratoryAssessments>) => !formState?.values.coagulationSameAsAbove;
const isUrinalysisAbnormal = ({ lookups, formState }: ICrfConditionParameters<Dtos.LaboratoryAssessments>) => GetFieldLookupItem(lookups, 'urinalysisResult', formState?.values.urinalysisResult)?.type === Dtos.LookupAbnormalityType.Abnormal;
const isUrinalysisPlannedTreatmentOther = ({ lookups, formState }: ICrfConditionParameters<Dtos.LaboratoryAssessments>) => GetFieldLookupItem(lookups, 'urinalysisPlannedTreatment', formState?.values.urinalysisPlannedTreatment)?.type === Dtos.LookupPlannedTreatmentType.OtherSpecify;


/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

export const THREE_DIGIT_POSITIVE_INTEGER_TWO_DECIMAL_PLACE = {
    decimalScale: 2,
    allowNegative: false,
    isAllowed: (values: NumberFormatValues) => {
        if (!values.floatValue) {
            return true;
        }

        if (values.floatValue < 1000) {
            return true;
        }

        return false;
    }
}

export const THREE_DIGIT_POSITIVE_INTEGER_ONE_DECIMAL_PLACE = {
    decimalScale: 1,
    allowNegative: false,
    isAllowed: (values: NumberFormatValues) => {
        if (!values.floatValue) {
            return true;
        }

        if (values.floatValue < 1000) {
            return true;
        }

        return false;
    }
}

const biochemistryHeader: IStaticGridHeaderProps[] = [
    {
        columnName: 'test',
        content: '',
        width: '25%',
        minWidth: 300
    },
    {
        columnName: 'value',
        content: 'Value',
        width: '25%',
        minWidth: 200
    },
    {
        columnName: 'lln',
        content: 'LLN',
        width: '25%',
        minWidth: 200
    },
    {
        columnName: 'uln',
        content: 'ULN',
        width: '25%',
        minWidth: 200
    }
];

const sodiumRow: IStaticGridCellProps[] = [
    {
        columnName: 'test',
        content: (
            <FormLabel />
        ),
        fieldName: 'sodium'
    },
    {
        columnName: 'value',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Typography color="textSecondary">mmol/L&nbsp;</Typography>
                        </InputAdornment>
                    )
                }}
                numberFormat={THREE_DIGIT_POSITIVE_INTEGER_TWO_DECIMAL_PLACE}
            />
        ),
        fieldName: 'sodium'
    }
];

const potassiumRow: IStaticGridCellProps[] = [
    {
        columnName: 'test',
        content: (
            <FormLabel />
        ),
        fieldName: 'potassium'
    },
    {
        columnName: 'value',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Typography color="textSecondary">mmol/L&nbsp;</Typography>
                        </InputAdornment>
                    )
                }}
                numberFormat={THREE_DIGIT_POSITIVE_INTEGER_TWO_DECIMAL_PLACE}
            />
        ),
        fieldName: 'potassium'
    }
];

const chlorideRow: IStaticGridCellProps[] = [
    {
        columnName: 'test',
        content: (
            <FormLabel />
        ),
        fieldName: 'chloride'
    },
    {
        columnName: 'value',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Typography color="textSecondary">mmol/L&nbsp;</Typography>
                        </InputAdornment>
                    )
                }}
                numberFormat={THREE_DIGIT_POSITIVE_INTEGER_TWO_DECIMAL_PLACE}
            />
        ),
        fieldName: 'chloride'
    }
];

const serumUreaRow: IStaticGridCellProps[] = [
    {
        columnName: 'test',
        content: (
            <FormLabel />
        ),
        fieldName: 'serumUrea'
    },
    {
        columnName: 'value',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Typography color="textSecondary">mmol/L&nbsp;</Typography>
                        </InputAdornment>
                    )
                }}
                numberFormat={THREE_DIGIT_POSITIVE_INTEGER_TWO_DECIMAL_PLACE}
            />
        ),
        fieldName: 'serumUrea'
    }
];

const serumCreatinineRow: IStaticGridCellProps[] = [
    {
        columnName: 'test',
        content: (
            <FormLabel />
        ),
        fieldName: 'serumCreatinine'
    },
    {
        columnName: 'value',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Typography color="textSecondary">&micro;mol/L&nbsp;</Typography>
                        </InputAdornment>
                    )
                }}
                numberFormat={THREE_DIGIT_POSITIVE_INTEGER_TWO_DECIMAL_PLACE}
            />
        ),
        fieldName: 'serumCreatinine'
    },
    {
        columnName: 'lln',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Typography color="textSecondary">&micro;mol/L&nbsp;</Typography>
                        </InputAdornment>
                    )
                }}
                numberFormat={THREE_DIGIT_POSITIVE_INTEGER_TWO_DECIMAL_PLACE}
            />
        ),
        fieldName: 'serumCreatinineLln'
    },
    {
        columnName: 'uln',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Typography color="textSecondary">&micro;mol/L&nbsp;</Typography>
                        </InputAdornment>
                    )
                }}
                numberFormat={THREE_DIGIT_POSITIVE_INTEGER_TWO_DECIMAL_PLACE}
            />
        ),
        fieldName: 'serumCreatinineUln'
    }
];

const orContainerStyles: React.CSSProperties = {
    position: 'relative'
};

const orStyles: React.CSSProperties = {
    position: 'absolute',
    top: '-36px',
    margin: '0 auto',
    width: '42px',
    left: '0',
    right: '0'
}

const orPcvContainerStyles: React.CSSProperties = {
    position: 'relative',
    display: 'flex',
    alignItems: 'end',
    height: '100%'
};

const orPcvStyles: React.CSSProperties = {
    position: 'absolute',
    top: '-30px',
    margin: '0 auto',
    width: '42px',
    left: '0',
    right: '0'
}

const calculatedCreatinineRow: IStaticGridCellProps[] = [
    {
        columnName: 'test',
        content: (
            
            <div style={orContainerStyles}>
                <div style={orStyles}>
                    <Chip label="and/or" />
                </div>
                <FormLabel />
            </div>
        ),
        fieldName: 'calculatedCreatinine'
    },
    {
        columnName: 'value',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Typography color="textSecondary">mL/min&nbsp;</Typography>
                        </InputAdornment>
                    )
                }}
                numberFormat={THREE_DIGIT_POSITIVE_INTEGER_TWO_DECIMAL_PLACE}
            />
        ),
        fieldName: 'calculatedCreatinine'
    },
    {
        columnName: 'lln',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Typography color="textSecondary">&micro;mol/L&nbsp;</Typography>
                        </InputAdornment>
                    )
                }}
                numberFormat={THREE_DIGIT_POSITIVE_INTEGER_TWO_DECIMAL_PLACE}
            />
        ),
        fieldName: 'calculatedCreatinineLln'
    },
];

const totalProteinRow: IStaticGridCellProps[] = [
    {
        columnName: 'test',
        content: (
            <FormLabel />
        ),
        fieldName: 'totalProtein'
    },
    {
        columnName: 'value',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Typography color="textSecondary">g/L&nbsp;</Typography>
                        </InputAdornment>
                    )
                }}
                numberFormat={THREE_DIGIT_POSITIVE_INTEGER_TWO_DECIMAL_PLACE}
            />
        ),
        fieldName: 'totalProtein'
    }
];

const albuminRow: IStaticGridCellProps[] = [
    {
        columnName: 'test',
        content: (
            <FormLabel />
        ),
        fieldName: 'albumin'
    },
    {
        columnName: 'value',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Typography color="textSecondary">g/L&nbsp;</Typography>
                        </InputAdornment>
                    )
                }}
                numberFormat={THREE_DIGIT_POSITIVE_INTEGER_TWO_DECIMAL_PLACE}
            />
        ),
        fieldName: 'albumin'
    }
];

const totalBilirubinRow: IStaticGridCellProps[] = [
    {
        columnName: 'test',
        content: (
            <FormLabel />
        ),
        fieldName: 'totalBilirubin'
    },
    {
        columnName: 'value',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Typography color="textSecondary">&micro;mol/L&nbsp;</Typography>
                        </InputAdornment>
                    )
                }}
                numberFormat={THREE_DIGIT_POSITIVE_INTEGER_TWO_DECIMAL_PLACE}
            />
        ),
        fieldName: 'totalBilirubin'
    },
    {
        columnName: 'uln',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Typography color="textSecondary">&micro;mol/L&nbsp;</Typography>
                        </InputAdornment>
                    )
                }}
                numberFormat={THREE_DIGIT_POSITIVE_INTEGER_TWO_DECIMAL_PLACE}
            />
        ),
        fieldName: 'totalBilirubinUln'
    }
];

const altRow: IStaticGridCellProps[] = [
    {
        columnName: 'test',
        content: (
            <FormLabel />
        ),
        fieldName: 'alt'
    },
    {
        columnName: 'value',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Typography color="textSecondary">U/L&nbsp;</Typography>
                        </InputAdornment>
                    )
                }}
                numberFormat={THREE_DIGIT_POSITIVE_INTEGER_TWO_DECIMAL_PLACE}
            />
        ),
        fieldName: 'alt'
    },
    {
        columnName: 'uln',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Typography color="textSecondary">U/L&nbsp;</Typography>
                        </InputAdornment>
                    )
                }}
                numberFormat={THREE_DIGIT_POSITIVE_INTEGER_TWO_DECIMAL_PLACE}
            />
        ),
        fieldName: 'altUln'
    }
];

const astRow: IStaticGridCellProps[] = [
    {
        columnName: 'test',
        content: (
            <FormLabel />
        ),
        fieldName: 'ast'
    },
    {
        columnName: 'value',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Typography color="textSecondary">U/L&nbsp;</Typography>
                        </InputAdornment>
                    )
                }}
                numberFormat={THREE_DIGIT_POSITIVE_INTEGER_TWO_DECIMAL_PLACE}
            />
        ),
        fieldName: 'ast'
    },
    {
        columnName: 'uln',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Typography color="textSecondary">U/L&nbsp;</Typography>
                        </InputAdornment>
                    )
                }}
                numberFormat={THREE_DIGIT_POSITIVE_INTEGER_TWO_DECIMAL_PLACE}
            />
        ),
        fieldName: 'astUln'
    }
];

const ggtRow: IStaticGridCellProps[] = [
    {
        columnName: 'test',
        content: (
            <FormLabel />
        ),
        fieldName: 'ggt'
    },
    {
        columnName: 'value',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Typography color="textSecondary">U/L&nbsp;</Typography>
                        </InputAdornment>
                    )
                }}
                numberFormat={THREE_DIGIT_POSITIVE_INTEGER_TWO_DECIMAL_PLACE}
            />
        ),
        fieldName: 'ggt'
    }
];

const alpRow: IStaticGridCellProps[] = [
    {
        columnName: 'test',
        content: (
            <FormLabel />
        ),
        fieldName: 'alp'
    },
    {
        columnName: 'value',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Typography color="textSecondary">U/L&nbsp;</Typography>
                        </InputAdornment>
                    )
                }}
                numberFormat={THREE_DIGIT_POSITIVE_INTEGER_TWO_DECIMAL_PLACE}
            />
        ),
        fieldName: 'alp'
    }
];

const ldhRow: IStaticGridCellProps[] = [
    {
        columnName: 'test',
        content: (
            <FormLabel />
        ),
        fieldName: 'ldh'
    },
    {
        columnName: 'value',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Typography color="textSecondary">U/L&nbsp;</Typography>
                        </InputAdornment>
                    )
                }}
                numberFormat={THREE_DIGIT_POSITIVE_INTEGER_TWO_DECIMAL_PLACE}
            />
        ),
        fieldName: 'ldh'
    }
];

const correctedCalciumRow: IStaticGridCellProps[] = [
    {
        columnName: 'test',
        content: (
            <FormLabel />
        ),
        fieldName: 'correctedCalcium'
    },
    {
        columnName: 'value',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Typography color="textSecondary">mmol/L&nbsp;</Typography>
                        </InputAdornment>
                    )
                }}
                numberFormat={THREE_DIGIT_POSITIVE_INTEGER_TWO_DECIMAL_PLACE}
            />
        ),
        fieldName: 'correctedCalcium'
    }
];

const magnesiumRow: IStaticGridCellProps[] = [
    {
        columnName: 'test',
        content: (
            <FormLabel />
        ),
        fieldName: 'magnesium'
    },
    {
        columnName: 'value',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Typography color="textSecondary">mmol/L&nbsp;</Typography>
                        </InputAdornment>
                    )
                }}
                numberFormat={THREE_DIGIT_POSITIVE_INTEGER_TWO_DECIMAL_PLACE}
            />
        ),
        fieldName: 'magnesium'
    }
];

const phosphateRow: IStaticGridCellProps[] = [
    {
        columnName: 'test',
        content: (
            <FormLabel />
        ),
        fieldName: 'phosphate'
    },
    {
        columnName: 'value',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Typography color="textSecondary">mmol/L&nbsp;</Typography>
                        </InputAdornment>
                    )
                }}
                numberFormat={THREE_DIGIT_POSITIVE_INTEGER_TWO_DECIMAL_PLACE}
            />
        ),
        fieldName: 'phosphate'
    }
];

const randomGlucoseRow: IStaticGridCellProps[] = [
    {
        columnName: 'test',
        content: (
            <FormLabel />
        ),
        fieldName: 'randomGlucose'
    },
    {
        columnName: 'value',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Typography color="textSecondary">mmol/L&nbsp;</Typography>
                        </InputAdornment>
                    )
                }}
                numberFormat={THREE_DIGIT_POSITIVE_INTEGER_TWO_DECIMAL_PLACE}
            />
        ),
        fieldName: 'randomGlucose'
    }
];

const amylaseRow: IStaticGridCellProps[] = [
    {
        columnName: 'test',
        content: (
            <FormLabel />
        ),
        fieldName: 'amylase'
    },
    {
        columnName: 'value',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Typography color="textSecondary">U/L&nbsp;</Typography>
                        </InputAdornment>
                    )
                }}
                numberFormat={THREE_DIGIT_POSITIVE_INTEGER_TWO_DECIMAL_PLACE}
            />
        ),
        fieldName: 'amylase'
    }
];

const lipaseRow: IStaticGridCellProps[] = [
    {
        columnName: 'test',
        content: (
            <FormLabel />
        ),
        fieldName: 'lipase'
    },
    {
        columnName: 'value',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Typography color="textSecondary">U/L&nbsp;</Typography>
                        </InputAdornment>
                    )
                }}
                numberFormat={THREE_DIGIT_POSITIVE_INTEGER_TWO_DECIMAL_PLACE}
            />
        ),
        fieldName: 'lipase'
    }
];

// const cortisolRow: IStaticGridCellProps[] = [
//     {
//         columnName: 'test',
//         content: (
//             <FormLabel />
//         ),
//         fieldName: 'cortisol'
//     },
//     {
//         columnName: 'value',
//         content: (
//             <Input
//                 component={Numeric}
//                 InputProps={{
//                     endAdornment: (
//                         <InputAdornment position="end">
//                             <Typography color="textSecondary">nmol/L&nbsp;</Typography>
//                         </InputAdornment>
//                     )
//                 }}
//                 numberFormat={THREE_DIGIT_POSITIVE_INTEGER_TWO_DECIMAL_PLACE}
//             />
//         ),
//         fieldName: 'cortisol'
//     }
// ];

const cReactiveProteinRow: IStaticGridCellProps[] = [
    {
        columnName: 'test',
        content: (
            <FormLabel />
        ),
        fieldName: 'cReactiveProtein'
    },
    {
        columnName: 'value',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Typography color="textSecondary">mg/L&nbsp;</Typography>
                        </InputAdornment>
                    )
                }}
                numberFormat={THREE_DIGIT_POSITIVE_INTEGER_TWO_DECIMAL_PLACE}
            />
        ),
        fieldName: 'cReactiveProtein'
    }
];

const inrRow: IStaticGridCellProps[] = [
    {
        columnName: 'test',
        content: (
            <FormLabel />
        ),
        fieldName: 'inr'
    },
    {
        columnName: 'value',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Typography color="textSecondary">ratio&nbsp;</Typography>
                        </InputAdornment>
                    )
                }}
                numberFormat={THREE_DIGIT_POSITIVE_INTEGER_TWO_DECIMAL_PLACE}
            />
        ),
        fieldName: 'inr'
    },
    {
        columnName: 'uln',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Typography color="textSecondary">ratio&nbsp;</Typography>
                        </InputAdornment>
                    )
                }}
                numberFormat={THREE_DIGIT_POSITIVE_INTEGER_TWO_DECIMAL_PLACE}
            />
        ),
        fieldName: 'inrUln'
    }
];

const apptRow: IStaticGridCellProps[] = [
    {
        columnName: 'test',
        content: (
            <FormLabel />
        ),
        fieldName: 'appt'
    },
    {
        columnName: 'value',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Typography color="textSecondary">s&nbsp;</Typography>
                        </InputAdornment>
                    )
                }}
                numberFormat={THREE_DIGIT_POSITIVE_INTEGER_TWO_DECIMAL_PLACE}
            />
        ),
        fieldName: 'appt'
    },
    {
        columnName: 'uln',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Typography color="textSecondary">s&nbsp;</Typography>
                        </InputAdornment>
                    )
                }}
                numberFormat={THREE_DIGIT_POSITIVE_INTEGER_TWO_DECIMAL_PLACE}
            />
        ),
        fieldName: 'apptUln'
    }
];

const pptRow: IStaticGridCellProps[] = [
    {
        columnName: 'test',
        content: (
            <FormLabel />
        ),
        fieldName: 'ppt'
    },
    {
        columnName: 'value',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Typography color="textSecondary">s&nbsp;</Typography>
                        </InputAdornment>
                    )
                }}
                numberFormat={THREE_DIGIT_POSITIVE_INTEGER_TWO_DECIMAL_PLACE}
            />
        ),
        fieldName: 'ppt'
    }
];

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Permission[] = [
    Permission.OpmsCrfUpdate,
    Permission.OpmsAdminister,
];

const LaboratoryAssessmentForm: React.FunctionComponent = () => {
    const classes = useStyles();

    const { values: formValues } = useFormState<Dtos.LaboratoryAssessments, any>({ values: true });

    const { setFieldValue } = useFormActions();

    React.useEffect(() => {
        if (formValues.hepatitisSameAsAbove) {
            setFieldValue(`hepatitisDate`, formValues.thyroidFunctionDate);
        }

        if (formValues.urinalysisSameAsAbove) {
            setFieldValue(`urinalysisDate`, formValues.hepatitisDate);
        }

        if (formValues.biochemistrySameAsAbove) {
            setFieldValue(`biochemistryDate`, formValues.haematologyDate);
        }

        if (formValues.cortisolDate) {
            setFieldValue(`cortisolDate`, formValues.cortisolDate);
        }

        if (formValues.coagulationSameAsAbove) {
            setFieldValue(`coagulationDate`, formValues.biochemistryDate);
        }
    }, [formValues, setFieldValue])

    return (
        <>
            <FieldGroup>
                <Typography
                    variant="h3"
                    color="primary"
                    className={classes.subHeading}
                >
                    Thyroid Function
                </Typography>
                <Field
                    name="thyroidFunctionDate"
                    component={KeyboardDatePicker}
                />
                <Field
                    name="tsh"
                    component={Numeric}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Typography color="textSecondary">mIU/L&nbsp;</Typography>
                            </InputAdornment>
                        )
                    }}
                    numberFormat={THREE_DIGIT_POSITIVE_INTEGER_TWO_DECIMAL_PLACE}
                />
                <Field
                    name="t3"
                    component={Numeric}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Typography color="textSecondary">pmol/L&nbsp;</Typography>
                            </InputAdornment>
                        )
                    }}
                    numberFormat={THREE_DIGIT_POSITIVE_INTEGER_TWO_DECIMAL_PLACE}
                />
                <Field
                    name="t4"
                    component={Numeric}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Typography color="textSecondary">pmol/L&nbsp;</Typography>
                            </InputAdornment>
                        )
                    }}
                    numberFormat={THREE_DIGIT_POSITIVE_INTEGER_TWO_DECIMAL_PLACE}
                />
            </FieldGroup>
            <FieldGroup>
                <Typography
                    variant="h3"
                    color="primary"
                    className={classes.subHeading}
                >
                    Hepatitis Testing
                </Typography>
                <Field
                    name="hepatitisDate"
                    component={KeyboardDatePicker}
                />
                <Field
                    name="hepatitisSameAsAbove"
                    component={Checkbox}
                    label=""
                    paddingTop={0}
                />
                <Field
                    name="hbcab"
                    component={Select}
                />
                <Field
                    name="hbsag"
                    component={Select}
                />
                <Field
                    name="hbvDna"
                    component={Select}
                />
                <Field
                    name="hcvRna"
                    component={Select}
                />
            </FieldGroup>
            <FieldGroup>
                <Typography
                    variant="h3"
                    color="primary"
                    className={classes.subHeading}
                >
                    Urinalysis
                </Typography>
                <Field
                    name="urinalysisDate"
                    component={KeyboardDatePicker}
                />
                <Field
                    name="urinalysisSameAsAbove"
                    component={Checkbox}
                    label=""
                    paddingTop={0}
                />
                <Field
                    name="urinalysisResult"
                    component={Select}
                />
                <CrfCondition
                    type={Dtos.LaboratoryAssessments}
                    condition={isUrinalysisAbnormal}
                    mode="Enable"
                    subscription={{ values: true }}
                >
                    <Field
                        name="urinalysisPlannedTreatment"
                        component={Select}
                    />
                </CrfCondition>
                <CrfCondition
                    type={Dtos.LaboratoryAssessments}
                    condition={isUrinalysisPlannedTreatmentOther}
                    mode="Enable"
                    subscription={{ values: true }}
                >
                    <Field
                        name="urinalysisPlannedTreatmentSpecify"
                        component={Text}
                    />
                </CrfCondition>
            </FieldGroup>
            <FieldGroup paddingBottom={0}>
                <Typography
                    variant="h3"
                    color="primary"
                    className={classes.subHeading}
                >
                    Haematology
                </Typography>
                <FieldGroup paddingBottom={0} paddingTop={0}>
                    <Field
                        name="haematologyDate"
                        component={KeyboardDatePicker}
                        isGrouped={true}
                    />
                </FieldGroup>
                <FieldGroup paddingBottom={0} paddingTop={0}>
                    <Field
                        name="haemoglobin"
                        component={Numeric}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Typography color="textSecondary">g/L&nbsp;</Typography>
                                </InputAdornment>
                            )
                        }}
                        numberFormat={THREE_DIGIT_POSITIVE_INTEGER_TWO_DECIMAL_PLACE}
                    />
                </FieldGroup>
                <FieldGroup paddingBottom={0} paddingTop={0}>
                    <Field
                        name="haematocrit"
                        component={Numeric}
                        numberFormat={THREE_DIGIT_POSITIVE_INTEGER_TWO_DECIMAL_PLACE}
                    />
                </FieldGroup>
                <FieldGroup paddingBottom={0} paddingTop={0}>
                    <div style={orStyles}>
                        <Chip label="and/or" />
                    </div>
                    <Field
                        name="pcv"
                        label={
                            <div style={{ height: '100%', width: '30%' }}>
                                <div style={orPcvContainerStyles} >
                                    <div style={orPcvStyles}>
                                        <Chip label="and/or" />
                                    </div>
                                    <FormLabel />
                                </div>
                            </div>
                        }
                        component={Numeric}
                        numberFormat={THREE_DIGIT_POSITIVE_INTEGER_TWO_DECIMAL_PLACE}
                    />
                </FieldGroup>
                <FieldGroup paddingBottom={0} paddingTop={0}>
                    <Field
                        name="platelets"
                        component={Numeric}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Typography color="textSecondary">x10<sup>9</sup>/L&nbsp;</Typography>
                                </InputAdornment>
                            )
                        }}
                        numberFormat={THREE_DIGIT_POSITIVE_INTEGER_TWO_DECIMAL_PLACE}
                    />
                </FieldGroup>
                <FieldGroup paddingBottom={0} paddingTop={0}>
                    <Field
                        name="rbcCount"
                        component={Numeric}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Typography color="textSecondary">x10<sup>12</sup>/L&nbsp;</Typography>
                                </InputAdornment>
                            )
                        }}
                        numberFormat={THREE_DIGIT_POSITIVE_INTEGER_TWO_DECIMAL_PLACE}
                    />
                </FieldGroup>
                <FieldGroup paddingBottom={0} paddingTop={0}>
                    <Field
                        name="wbcCount"
                        component={Numeric}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Typography color="textSecondary">x10<sup>9</sup>/L&nbsp;</Typography>
                                </InputAdornment>
                            )
                        }}
                        numberFormat={THREE_DIGIT_POSITIVE_INTEGER_TWO_DECIMAL_PLACE}
                    />
                </FieldGroup>
                <FieldGroup paddingBottom={0} paddingTop={0}>
                    <Field
                        name="absoluteNeutrophilCount"
                        component={Numeric}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Typography color="textSecondary">x10<sup>9</sup>/L&nbsp;</Typography>
                                </InputAdornment>
                            )
                        }}
                        numberFormat={THREE_DIGIT_POSITIVE_INTEGER_TWO_DECIMAL_PLACE}
                    />
                </FieldGroup>
                <FieldGroup paddingBottom={0} paddingTop={0}>
                    <Field
                        name="lymphocytes"
                        component={Numeric}
                        numberFormat={THREE_DIGIT_POSITIVE_INTEGER_TWO_DECIMAL_PLACE}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Typography color="textSecondary">x10<sup>9</sup>/L&nbsp;</Typography>
                                </InputAdornment>
                            )
                        }}
                    />
                </FieldGroup>
                <FieldGroup paddingBottom={0} paddingTop={0}>
                    <Field
                        name="monocytes"
                        component={Numeric}
                        numberFormat={THREE_DIGIT_POSITIVE_INTEGER_TWO_DECIMAL_PLACE}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Typography color="textSecondary">x10<sup>9</sup>/L&nbsp;</Typography>
                                </InputAdornment>
                            )
                        }}
                    />
                </FieldGroup>
                <FieldGroup paddingBottom={0} paddingTop={0}>
                    <Field
                        name="eosinophils"
                        component={Numeric}
                        numberFormat={THREE_DIGIT_POSITIVE_INTEGER_TWO_DECIMAL_PLACE}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Typography color="textSecondary">x10<sup>9</sup>/L&nbsp;</Typography>
                                </InputAdornment>
                            )
                        }}
                    />
                </FieldGroup>
                <FieldGroup paddingBottom={0} paddingTop={0}>
                    <Field
                        name="basophils"
                        component={Numeric}
                        numberFormat={THREE_DIGIT_POSITIVE_INTEGER_TWO_DECIMAL_PLACE}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Typography color="textSecondary">x10<sup>9</sup>/L&nbsp;</Typography>
                                </InputAdornment>
                            )
                        }}
                    />
                </FieldGroup>
            </FieldGroup>
            <FieldGroup paddingBottom={0}>
                <Typography
                    variant="h3"
                    color="primary"
                    className={classes.subHeading}
                >
                    Biochemistry
                </Typography>
                <Field
                    name="biochemistryDate"
                    component={KeyboardDatePicker}
                />
                <Field
                    name="biochemistrySameAsAbove"
                    component={Checkbox}
                    label=""
                    paddingTop={0}
                />
                <Field
                    name="gilbertSyndrome"
                    component={ToggleButtonGroup}
                />
                <Field
                    name="liverMetastases"
                    component={ToggleButtonGroup}
                />
                <StaticGrid
                    headers={biochemistryHeader}
                    stickyHeader={true}
                >
                    <StaticGridRow cells={sodiumRow} />
                    <StaticGridRow cells={potassiumRow} />
                    <StaticGridRow cells={chlorideRow} />
                    <StaticGridRow cells={serumUreaRow} />
                    <StaticGridRow cells={serumCreatinineRow} />
                    <StaticGridRow cells={calculatedCreatinineRow} />
                    <StaticGridRow cells={totalProteinRow} />
                    <StaticGridRow cells={albuminRow} />
                    <StaticGridRow cells={totalBilirubinRow} />
                    <StaticGridRow cells={altRow} />
                    <StaticGridRow cells={astRow} />
                    <StaticGridRow cells={ggtRow} />
                    <StaticGridRow cells={alpRow} />
                    <StaticGridRow cells={ldhRow} />
                    <StaticGridRow cells={correctedCalciumRow} />
                    <StaticGridRow cells={magnesiumRow} />
                    <StaticGridRow cells={phosphateRow} />
                    <StaticGridRow cells={randomGlucoseRow} />
                    <StaticGridRow cells={amylaseRow} />
                    <StaticGridRow cells={lipaseRow} />
                    <StaticGridRow cells={cReactiveProteinRow} />
                </StaticGrid>
            </FieldGroup>
            <FieldGroup paddingBottom={0}>
                <Typography
                    variant="h3"
                    color="primary"
                    className={classes.subHeading}
                >
                    Cortisol Testing
                </Typography>
                <FieldGroup paddingBottom={0} paddingTop={0}>
                    <Field
                        name="cortisolDate"
                        component={KeyboardDatePicker}
                    />
                </FieldGroup>
                <FieldGroup paddingBottom={0} paddingTop={0}>
                    <Field
                        name="cortisol"
                        component={Numeric}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Typography color="textSecondary">nmol/L&nbsp;</Typography>
                                </InputAdornment>
                            )
                        }}
                        numberFormat={THREE_DIGIT_POSITIVE_INTEGER_TWO_DECIMAL_PLACE}
                    />
                </FieldGroup>
            </FieldGroup>
            <FieldGroup paddingBottom={0}>
                <Typography
                    variant="h3"
                    color="primary"
                    className={classes.subHeading}
                >
                    Coagulation Testing
                </Typography>
                <Field
                    name="coagulationDate"
                    component={KeyboardDatePicker}
                />
                <Field
                    name="coagulationSameAsAbove"
                    component={Checkbox}
                    label=""
                    paddingTop={0}
                />
                <StaticGrid
                    headers={biochemistryHeader}
                    stickyHeader={true}
                >
                    <StaticGridRow cells={inrRow} />
                    <StaticGridRow cells={apptRow} />
                    <StaticGridRow cells={pptRow} />
                </StaticGrid>
            </FieldGroup>
        </>
    );
}

const LaboratoryAssessments: React.FunctionComponent<ILaboratoryAssessmentsProps> = () => {
    const classes = useStyles();

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);

    const [[canUpdateCrf, ], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, true, false);

    if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }

    return (
        <>
            <FormBreadcrumbs />
            <CrfForm
                formType={Dtos.LaboratoryAssessments}
                validateOn="onChange"
                canEdit={canUpdateCrf}
            >
                <Typography
                    variant="h6"
                    className={classes.instruction}
                >
                    <strong>Instructions:</strong>
                    <ul>
                        <li>Results for Cortisol, Thyroid function, Hepatitis, and Urinalysis testing must be collected within 28 days of Registration.</li>
                        <li>Results for Coagulation, Haematology and Biochemistry testing must be collected within 7 days of Registration.</li>
                    </ul>
                </Typography>

                <LaboratoryAssessmentForm />

                <SourceDocuments />
            </CrfForm>
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default LaboratoryAssessments;
