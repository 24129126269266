/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the sae notify page component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';
import { InstitutionPersonnel, SaeBreadcrumbs } from '@ngt/opms-sae';
import { RequestState } from '@ngt/request-utilities';
import { usePermissionsByIds, Permission } from '@ngt/opms-bctapi';
import { Checkbox, Field, ICheckbox, InstitutionContext } from '@ngt/opms';
import { SaeNotificationForm } from '@ngt/opms-sae';
import { useHistory, useParams } from 'react-router-dom';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../../api/dtos'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-duotone-svg-icons';
import { FormControlLabel } from '@material-ui/core';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface ISaeNotifyPageParams {
    patientStudyNumber?: string,
    saeNumber?: string;
    notifyMode: string
}

interface ISaeNotifyPageProps {
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const permissions: Permission[] = [
    Permission.SaeView,
    Permission.SaeReview,
    Permission.SaeAdminister,
    Permission.MedicalReviewPerform
];

// const investigatorRoles = [13, 18];

// const medicalReviewerRoles = [48];

// const studyChairRoles = [3, 22];

/*
 * ---------------------------------------------------------------------------------
 * Component
 * ---------------------------------------------------------------------------------
 */

const bctEmailMatch = (window as any).SAE_NOTIFY?.bctEmailAddressMatch;

const bctMedicalReviewSharedMailbox = (window as any).SAE_NOTIFY?.bctMedicalReviewSharedMailbox;

const CheckboxWithReactNodeLabel: React.FC<ICheckbox & { checkboxLabel: React.ReactNode }> = ({checkboxLabel, ...props}) => {
    return (
        <FormControlLabel
            control={<Checkbox {...props} />}
            label={checkboxLabel}
        />
    )
}


const parseBooleanToString = (input?: boolean) => input ? 'true' : 'false';

const convertStringToBoolean = (field?: string) => field === 'true';
interface PersonnelLabelComponentProps {
    personnel: InstitutionPersonnel;
}

const DEFAULT_PERSONNEL_LABEL_COMPONENT: React.FC<PersonnelLabelComponentProps> = ({
    personnel
}) => {
    return (
        <div>
            <FontAwesomeIcon icon={faUser as any} color={bctEmailMatch && personnel.email.toLowerCase().includes(bctEmailMatch) ? 'green' : 'red'} style={{ marginRight: 8 }} />
            {personnel.name}
        </div>
    )
}

const SaeNotifyPage: React.FunctionComponent<ISaeNotifyPageProps> = () => {
    const history = useHistory();

    const params = useParams<ISaeNotifyPageParams>();

    const contextInstitution = React.useContext(InstitutionContext);

    const [[/*canViewSae*/, /*canReviewSae*/, canAdministerSae, /*canMedicallyReviewSae*/], saePermissionLoadState] = usePermissionsByIds(permissions, null, null, contextInstitution?.institution?.id, null, contextInstitution?.institution ? true : false);

    React.useEffect(() => {
        if (saePermissionLoadState.state !== RequestState.Pending &&
            saePermissionLoadState.state !== RequestState.None) {
            if (!canAdministerSae) {
                history.replace(`/error/403`);
            }
        }
    }, [canAdministerSae, saePermissionLoadState, history]);

    const setInitialValues = React.useCallback((initialValues: Dtos.SaeNotificationForm): Dtos.SaeNotificationForm => {
        if (params.notifyMode === 'mreviewer') {
            initialValues.additionalData = {
                SendBctEmailsToSharedMailbox: 'true'
            }
        }

        return initialValues;
    }, [params]);

    return (
        <>
            <SaeBreadcrumbs />

            <SaeNotificationForm
                notifyMode={params?.notifyMode}
                PersonnelLabelComponent={params.notifyMode === 'mreviewer' ? DEFAULT_PERSONNEL_LABEL_COMPONENT : undefined}
                setInitialFormValues={setInitialValues as any}
                AdditionalDataComponents={{
                    BeforeDocuments: params.notifyMode === 'mreviewer' ? () => {
                        return (
                            <Field
                                name="additionalData.SendBctEmailsToSharedMailbox"
                                component={CheckboxWithReactNodeLabel}
                                sm={9}
                                md={9}
                                xl={9}
                                lg={9}
                                parse={parseBooleanToString}
                                convert={convertStringToBoolean}
                                checkboxLabel={
                                    <>
                                        Send emails addressed to BCT personnel (<FontAwesomeIcon icon={faUser as any} color="green" />) to the BCT shared mailbox ({bctMedicalReviewSharedMailbox}).
                                    </>
                                }
                                paddingTop={0}
                            />
                        )
                    } : undefined
                }}
            />
        </>    
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */
export default SaeNotifyPage;