/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the sae view page component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';

import { useParams, useHistory } from 'react-router';

import { Theme, makeStyles } from '@material-ui/core';

import {
    SaeBreadcrumbs,
    SaeSummary,
    SaeInformation,
    SaeInformationFn,
    SaeStatus,
    SaeSummaryButtons,
    SaeInvestigatorReviewForm,
    SaeMedicalReviewForm,
    SaeContext,
    SaeReviewContext,
    ISaeReviewContext,
    InvalidSaeForm,

    SaeHistory
} from '@ngt/opms-sae'

import { usePermissionsByIds } from '@ngt/opms-bctapi'

import { RequestState } from '@ngt/request-utilities';

import {
    InstitutionContext, RouteLoading,
} from '@ngt/opms';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../../api/dtos';
import useSaeColumns from '../../hooks/sae/useSaeColumns';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface ISaeViewPageParams {
    patientStudyNumber?: string,
    saeNumber?: string;
    viewMode?: string;
}

interface ISaeViewPageProps {
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    hidden: {
        display: 'none'
    },
    ml1: {
        marginLeft: theme.spacing(1),
    },
    mt1: {
        marginTop: theme.spacing(1),
    },
    mt2: {
        marginTop: theme.spacing(2),
    },
    mt3: {
        marginTop: theme.spacing(3)
    },
    mb3: {
        marginBottom: theme.spacing(3),
    },
    mt4: {
        marginTop: theme.spacing(4)
    },
}));

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const permissions: Dtos.Permission[] = [
    Dtos.Permission.SaeView,
    Dtos.Permission.SaeReview,
    Dtos.Permission.SaeAdminister,
    Dtos.Permission.MedicalReviewPerform
];

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */
const ValidSaeView = () => {
    const classes = useStyles();

    const params = useParams<ISaeViewPageParams>();

    const { institution } = React.useContext(InstitutionContext);

    const { sae } = React.useContext(SaeContext);

    const [[, canReviewSae, /* canAdministerSae */, canMedicalReviewSae], ] = usePermissionsByIds(permissions, null, null, institution?.id, null, false);

    const { hasMedicalReview } = React.useContext<ISaeReviewContext>(SaeReviewContext);

    const saeDataColumns = useSaeColumns(null, false) as any;

    return (
        <>
            {
                params?.viewMode === 'review' &&
                !!canReviewSae &&
                <div className={classes.mb3}>
                    <SaeInvestigatorReviewForm />
                </div>
            }

            {
                params?.viewMode === 'mreview' &&
                !!hasMedicalReview &&
                !!canMedicalReviewSae &&
                <div className={classes.mb3}>
                    <SaeHistory
                        patientStudyNumber={sae?.patient?.studyNumber}
                        saeNumber={sae?.saeNumber}
                        institutionCode={institution?.code}
                        columns={saeDataColumns}
                    />
                    <SaeMedicalReviewForm
                        patientStudyNumber={sae?.patient.studyNumber}
                        saeNumber={sae?.saeNumber}
                    />
                </div>
            }

            {
                params?.viewMode !== 'review' &&
                params?.viewMode !== 'mreview' &&
                <SaeSummaryButtons />
            }

            <SaeSummary
                showMedicalReview={!!hasMedicalReview && !!canMedicalReviewSae}
            />
        </>    
    )
}

const InvalidSaeView = () => {
    const contextInstitution = React.useContext(InstitutionContext);

    const [[, , canAdministerSae, ], ] = usePermissionsByIds(permissions, null, null, contextInstitution?.institution?.id, null, false);

    return (
        <>
            <InvalidSaeForm readOnly={!canAdministerSae} />
        </>
    )
}

const SaeViewPage: React.FunctionComponent<ISaeViewPageProps> = () => {
    const classes = useStyles();

    const history = useHistory();

    const params = useParams<ISaeViewPageParams>();

    const viewMode = params?.viewMode;

    const { hasMedicalReview } = React.useContext<ISaeReviewContext>(SaeReviewContext);

    const contextInstitution = React.useContext(InstitutionContext);

    const institution = contextInstitution?.institution;

    const [[canViewSae, , /* canAdministerSae */, ], saePermissionLoadState] = usePermissionsByIds(permissions, null, null, contextInstitution?.institution?.id, null, contextInstitution?.institution ? true : false);

    const patientCaption: SaeInformationFn = (patient) => (patient as Dtos.Patient)?.initials
    const saeText: SaeInformationFn = (patient, institution, sae) => !!sae ? `${sae?.saeNumber} (${sae?.form.followUpNumber === 0 ? 'Initial' : 'Follow Up No.' + sae?.form.followUpNumber})` : '';
    const saeCaption: SaeInformationFn = (patient, institution, sae) => getSaeStatus((sae as any as Dtos.Sae<Dtos.ISaeForm, Dtos.IPatient>)?.status) ?? '';

    const { sae, loadState: saeLoadState, actions: saeActions } = React.useContext(SaeContext);

    React.useEffect(() => {
        if (viewMode === 'mreview' && !hasMedicalReview) {
            history.replace(`/sae/${institution?.code}/${sae?.patient?.studyNumber}/${sae?.saeNumber}`);
        }
    }, [viewMode, hasMedicalReview, institution, sae, history]);

    React.useEffect(() => {
        if (saePermissionLoadState.state !== RequestState.Pending &&
            saePermissionLoadState.state !== RequestState.None) {
            if (!canViewSae) {
                history.replace(`/error/403`);
            }
        }
    }, [canViewSae, saePermissionLoadState, history]);

    React.useEffect(() => {
        saeActions.load();

        return () => {
            saeActions.clear();
        }
    }, [viewMode, saeActions]);

    if (saeLoadState.state === RequestState.None || saeLoadState.state === RequestState.Pending) {
        return <RouteLoading />;
    }

    return (
        <>
            <SaeBreadcrumbs institution={contextInstitution?.institution} />

            <div
                className={classes.container}
            >
                <SaeInformation patientCaption={patientCaption} saeText={saeText} saeCaption={saeCaption} />

                {
                    sae?.status !== Dtos.SaeStatus.Invalid && viewMode !== 'invalid' ?
                        <ValidSaeView />
                        :
                        <InvalidSaeView/>
                }
            </div>
        </>
    );
}

export const getSaeStatus = (status?: Dtos.SaeStatus) => {
    switch (status) {
        case Dtos.SaeStatus.Added: return 'New';
        case Dtos.SaeStatus.New: return 'New';
        case Dtos.SaeStatus.AwaitingForReview: return 'Awaiting for review';
        case Dtos.SaeStatus.Approved: return 'Approved';
        case Dtos.SaeStatus.Rejected: return 'Rejected';
        case Dtos.SaeStatus.Invalid: return 'Invalid';
    }

    return;
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */
export default SaeViewPage;