/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';

/**
 * Used for the basic page layout.
 */
import {
    CrfFormByCodesRoutes,
    ICrfFormMapping,
    IMenuItem,
    InstitutionsByCodesRoute,
    OnlinePatientManagementContext,
    OnlinePatientManagementLayout,
    PatientSummaryByStudyNumberRoute,
    RouteLoading,
    UserType,
    useAuthenticatedUser,
    useIsMobile
} from '@ngt/opms';

import { EConsentPage } from '@ngt/opms-econsent';

import { SaeByPatientStudyNumberRoute } from '@ngt/opms-sae';

import { Permission, usePermissionsByIds } from '@ngt/opms-bctapi';

import { RequestState } from '@ngt/request-utilities';

/**
 * Used for font styling.
 */
import { Typography } from '@material-ui/core';

/**
 * Used to display icons
 */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Used for the copyright icon.
 */
import { faCopyright } from '@fortawesome/pro-regular-svg-icons/faCopyright';

/**
 * Used for the patient icon.
 */
import { faUser } from '@fortawesome/pro-duotone-svg-icons/faUser';

/**
 * Used for the ecrf icon.
 */

/**
 * Used for the sae icon.
 */
import { faFileMedical as saeIcon } from '@fortawesome/pro-duotone-svg-icons/faFileMedical';

/**
 * Used for the screening log icon.
 */
import { faFileAlt as screeningLogIcon } from '@fortawesome/pro-duotone-svg-icons/faFileAlt';

/**
 * Used for the econsent icon.
 */
import { faFileSignature as eConsentIcon } from '@fortawesome/pro-duotone-svg-icons/faFileSignature';

/**
 * Used for the report icon.
 */
import { faFileChartLine } from '@fortawesome/pro-duotone-svg-icons/faFileChartLine';

import { faExternalLink } from '@fortawesome/pro-duotone-svg-icons/faExternalLink';

import { faFirstAid } from '@fortawesome/pro-duotone-svg-icons/faFirstAid';

import { Redirect, Route, Switch } from 'react-router-dom';

import { AdministerDepot, AdministerPharmacy, BatchManagement, DepotManagement, DrugDispensations, DrugManagementContext, DrugManagementPage, IDrugManagementContext } from '@ngt/opms-drugmanagement';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

import * as Dtos from './api/dtos';
import IFramePage from './containers/IFramePage';
import PatientSummary from './containers/PatientSummary';
import Registration from './containers/Registration';
import Reports from './containers/Reports';
import Consent from './containers/form/Consent';
import Demographics from './containers/form/Demographics';
import EligibilityChecklist from './containers/form/EligibilityChecklist';
import General from './containers/form/General';
import ImagingAndTumourAssessment from './containers/form/ImagingAndTumourAssessment';
import LaboratoryAssessments from './containers/form/LaboratoryAssessments';
import MedicalHistoryAndConcomitantMedications from './containers/form/MedicalHistoryAndConcomitantMedications';
import PathologyAndPriorTreatment from './containers/form/PathologyAndPriorTreatment';
import PdlAndTilResult from './containers/form/PdlAndTilResult';
import TumourBlockShipment from './containers/form/TumourBlockShipment';
import TumourBlockSubmission from './containers/form/TumourBlockSubmission';
import WaiverForm from './containers/form/WaiverForm';
import SaeNotifyPage from './containers/sae/SaeNotifyPage';
import SaeViewPage from './containers/sae/SaeViewPage';
import SaesPage from './containers/sae/SaesPage';
import ScreeningLogPage from './containers/screeninglog/ScreeningLogPage';
import ErrorPage, { ErrorTypeEnum } from './containers/error/ErrorPage';
import PatientStatusForm from './containers/form/PatientStatusForm';

/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * constants
 * ----------------------------------------------------------------------------------
 */

const formMapping: Record<string, ICrfFormMapping> = {
    "demographics": {
        components: <Demographics />
    },
    "consent": {
        components: <Consent />
    },
    "eligibility-checklist": {
        components: <EligibilityChecklist />
    },
    "tumour-sample-submission": {
        components: <TumourBlockSubmission />
    },
    "tumour-sample-shipment": {
        components: <TumourBlockShipment />
    },
    "general": {
        components: <General />
    },
    "pathology-and-prior-treatment": {
        components: <PathologyAndPriorTreatment />
    },
    "imaging-and-tumour-assessment": {
        components: <ImagingAndTumourAssessment />
    },
    "laboratory-assessments": {
        components: <LaboratoryAssessments />
    },
    "medical-history-and-concomitant-medications": {
        components: <MedicalHistoryAndConcomitantMedications />
    },
    "pdl-and-til-result-form": {
        components: <PdlAndTilResult />
    },
    "waiver-form": {
        components: <WaiverForm />
    },
    "patient-status-form": {
        components: <PatientStatusForm />
    }
}

const eConsentSettings = global.ECONSENT_SETTINGS as Dtos.GetEConsentSettingsResponse;

const permissions: Permission[] = [
    Permission.OpmsAccess,
    Permission.SaeView,
    Permission.SaeReview,
    Permission.SaeAdminister,
    Permission.MedicalReviewPerform,
    Permission.ScreeningLogView,
    Permission.ScreeningLogUpdate,
    Permission.EConsentView,
    Permission.EConsentAdminister,
    Permission.EConsentManage,
    Permission.DrugManagementView,
    Permission.DrugManagementAdminister,
    Permission.DrugManagementDispenseDrug,
    Permission.OpmsDataManage,
    Permission.OpmsPatientRegister,
    Permission.PathologyTestAdminister
];

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const App: React.FunctionComponent = () => {
    const [user] = useAuthenticatedUser();

    const isPatient = user?.type === UserType.Patient;

    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);
    const drugManagementContext = React.useContext<IDrugManagementContext>(DrugManagementContext);
    const drugManagementBaseUrl = drugManagementContext?.baseUrl;

    const [[canAccessOpms, canViewSae, , , , canViewScreeningLog, , canViewEConsent, , , canViewDrugManagement, canAdministerDrugManagement, canDispenseDrug, , , ], permissionLoadState] = usePermissionsByIds(permissions, null, null, null, null, true);

    const menuItems: IMenuItem[] = React.useMemo(() => {

        if (isPatient) {
            return [];
        }

        let items: IMenuItem[] = [];

        if (canViewScreeningLog) {
            items.push({
                url: '/screening',
                label: 'Screening',
                external: false,
                newTab: false,
                icon: screeningLogIcon as any
            });
        }
        if (canViewEConsent && eConsentSettings.eConsentActivated) {
            items.push({
                url: '/econsent',
                label: 'eConsent',
                external: false,
                newTab: false,
                icon: eConsentIcon as any
            });
        }

        items.push(
            {
                url: '/registration',
                label: 'Registration',
                external: false,
                newTab: false,
                icon: faUser as any
            }
        );

        if (canViewSae) {
            items.push({
                url: '/sae',
                label: 'SAE Review',
                external: false,
                newTab: false,
                icon: saeIcon as any
            });
        }

        items.push(
            {
                url: 'https://ecrf.breastcancertrials.org.au',
                label: 'eCRF',
                external: true,
                newTab: true,
                icon: faExternalLink as any
            }
        );

        if (canViewDrugManagement) {
            items.push({
                url: drugManagementBaseUrl,
                label: 'Drug Management',
                external: false,
                newTab: false,
                icon: faFirstAid as any       
            });
        }

        items.push({
            url: '/reports',
            label: 'Reports',
            external: false,
            newTab: false,
            icon: faFileChartLine as any
        });

        //if (canViewDrugManagement || canViewSae) {
        //    items.push({
        //        url: '/reports',
        //        label: 'Reports',
        //        external: false,
        //        newTab: false,
        //        icon: faFileChartLine
        //    });
        //}

        return items;
    }, [isPatient, canViewEConsent, canViewSae, canViewScreeningLog, canViewDrugManagement, drugManagementBaseUrl]);

    const isMobile = useIsMobile();

    if ( permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }

    const InvalidPermission = (permissionLoadState.state === RequestState.Cancel || permissionLoadState.state === RequestState.Failure || permissionLoadState.state === RequestState.Success) && 
        !canAccessOpms &&
        !canViewSae && 
        !canViewScreeningLog && 
        !canViewEConsent && 
        !canViewDrugManagement;

    return (
        <OnlinePatientManagementLayout
            menuItems={menuItems}
            footer={
                isMobile ?
                    undefined :
                    <Typography variant="caption">
                        Copyright <FontAwesomeIcon icon={faCopyright} fixedWidth /> {(new Date().getFullYear())} ANZ Breast Cancer Trials Group Ltd | ABN 64 051 369 496
                    </Typography>
            }
        >
            {
                InvalidPermission ?
                <ErrorPage errorType={ErrorTypeEnum.e403} /> :
                <React.Suspense fallback={<RouteLoading />}>
                    <Switch>
                        <CrfFormByCodesRoutes
                            path={[
                                `/registration/:${onlinePatientManagement.routeParameters.institutionCode}/add-participant`
                            ]}
                            eventDefinitionCode="pre-registration"
                            eventRepeat={1}
                            formDefinitionCode="demographics"
                            formRepeat={1}
                            createPatient
                            resolveBeforeLoad
                            formMapping={formMapping}
                        />
                        <CrfFormByCodesRoutes
                            path={[
                                `/registration/:${onlinePatientManagement.routeParameters.institutionCode}/:${onlinePatientManagement.routeParameters.patientStudyNumber}/mark-ineligible`,
                            ]}
                            eventDefinitionCode="staff"
                            eventRepeat={1}
                            formDefinitionCode="patient-status-form"
                            formRepeat={1}
                            resolveBeforeLoad
                            formMapping={formMapping}
                        />
                        <CrfFormByCodesRoutes
                            path={[
                                `/registration/:${onlinePatientManagement.routeParameters.institutionCode}/:${onlinePatientManagement.routeParameters.patientStudyNumber}/:${onlinePatientManagement.routeParameters.eventDefinitionCode}/:${onlinePatientManagement.routeParameters.eventRepeat}/:${onlinePatientManagement.routeParameters.formDefinitionCode}/:${onlinePatientManagement.routeParameters.formRepeat}/:mode`,
                                `/registration/:${onlinePatientManagement.routeParameters.institutionCode}/:${onlinePatientManagement.routeParameters.patientStudyNumber}/:${onlinePatientManagement.routeParameters.eventDefinitionCode}/:${onlinePatientManagement.routeParameters.eventRepeat}/:${onlinePatientManagement.routeParameters.formDefinitionCode}/:${onlinePatientManagement.routeParameters.formRepeat}`
                            ]}
                            resolveBeforeLoad
                            formMapping={formMapping}
                        />
                        <PatientSummaryByStudyNumberRoute
                            path={[
                                `/registration/:${onlinePatientManagement.routeParameters.institutionCode}/:${onlinePatientManagement.routeParameters.patientStudyNumber}`
                            ]}
                            resolveBeforeLoad
                        >
                            <PatientSummary />
                        </PatientSummaryByStudyNumberRoute>
                        <InstitutionsByCodesRoute
                            path={[
                                `/registration/:${onlinePatientManagement.routeParameters.institutionCode}`,
                                '/registration'
                            ]}
                        >
                            <Registration />
                        </InstitutionsByCodesRoute>
                        <InstitutionsByCodesRoute
                            path={[
                                `/screening/:${onlinePatientManagement.routeParameters.institutionCode}`,
                                '/screening'
                            ]}
                        >
                            <ScreeningLogPage />
                        </InstitutionsByCodesRoute>
                        <InstitutionsByCodesRoute
                            path={[
                                `/econsent/:${onlinePatientManagement.routeParameters.institutionCode}`,
                                '/econsent'
                            ]}
                        >
                            <EConsentPage />
                        </InstitutionsByCodesRoute>
                        <SaeByPatientStudyNumberRoute
                            path={[
                                `/sae/:${onlinePatientManagement.routeParameters.institutionCode}/:${onlinePatientManagement.routeParameters.patientStudyNumber}/:saeNumber/notify/:notifyMode`,
                            ]}
                            resolveBeforeLoad
                            key="sae-notification-page"
                        >
                            <SaeNotifyPage />
                        </SaeByPatientStudyNumberRoute>
                        <SaeByPatientStudyNumberRoute
                            path={[
                                `/sae/:${onlinePatientManagement.routeParameters.institutionCode}/:${onlinePatientManagement.routeParameters.patientStudyNumber}/:saeNumber/:viewMode`,
                                `/sae/:${onlinePatientManagement.routeParameters.institutionCode}/:${onlinePatientManagement.routeParameters.patientStudyNumber}/:saeNumber`,
                            ]}
                            resolveBeforeLoad
                            key="sae-view-page"
                        >
                            <SaeViewPage />
                        </SaeByPatientStudyNumberRoute>
                        <InstitutionsByCodesRoute
                            path={[
                                `/sae/:${onlinePatientManagement.routeParameters.institutionCode}`,
                                '/sae'
                            ]}
                        >
                            <SaesPage />
                        </InstitutionsByCodesRoute>
                        <Route
                            path={[
                                `${drugManagementBaseUrl}/batches`
                            ]}
                        >
                            <BatchManagement />
                        </Route>
                        <Route
                            path={[
                                `${drugManagementBaseUrl}/depots`
                            ]}
                        >
                            <DepotManagement />
                        </Route>
                        <Route
                            path={[
                                `${drugManagementBaseUrl}/depot/:depotId`
                            ]}>
                            <AdministerDepot />
                        </Route>
                        <Route
                            path={[
                                `${drugManagementBaseUrl}/pharmacy/:pharmacyId`
                            ]}
                        >
                            <AdministerPharmacy
                                canAdministerDrugManagement={canAdministerDrugManagement} 
                                canAdministerPharmacyStock={canAdministerDrugManagement}                        
                            />
                        </Route>
                        <PatientSummaryByStudyNumberRoute
                            path={[
                                `/drug-management/:${onlinePatientManagement.routeParameters.institutionCode}/:${onlinePatientManagement.routeParameters.patientStudyNumber}/drug-dispensation`
                            ]}
                        >
                            <DrugDispensations
                                canAdministerDrugManagement={canAdministerDrugManagement}
                                canDispenseDrug={canDispenseDrug}
                            />
                        </PatientSummaryByStudyNumberRoute>
                        <Route
                            path={[
                                `${drugManagementBaseUrl}/:${onlinePatientManagement.routeParameters.institutionCode}`,
                                `${drugManagementBaseUrl}`
                            ]}>
                            <DrugManagementPage
                                canAdministerDrugManagement={canAdministerDrugManagement}
                                canViewDrugManagement={canViewDrugManagement}
                            />
                        </Route>
                        <InstitutionsByCodesRoute
                            path={[
                                '/reports'
                            ]}
                        >
                            <Reports />
                        </InstitutionsByCodesRoute>
                        <Route
                            path={[
                                '/forms-due-summary/'
                            ]}
                        >
                            <IFramePage url="/api/forms-due-summary-report" />
                        </Route>
                        {/*<InstitutionsByCodesRoute*/}
                        {/*    path={[*/}
                        {/*        `/reports/:${onlinePatientManagement.routeParameters.institutionCode}`,*/}
                        {/*        `/reports`*/}
                        {/*    ]}*/}
                        {/*    resolveBeforeLoad*/}
                        {/*>*/}
                        {/*    <Reports />*/}
                        {/*</InstitutionsByCodesRoute>*/}
                        <Redirect path="*" to="/registration" />
                        <Redirect path="*" to="/registration/admin" />
                    </Switch> 
                </React.Suspense>
            }
        </OnlinePatientManagementLayout>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default App;
