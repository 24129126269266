/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';

import './PatientSummary.css';

/*
 * Used to style components
 */
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip, Typography, makeStyles } from '@material-ui/core';
import { useForm } from 'react-hook-form';

/**
 * Used for the basic page layout.
 */
import {
    CollaboratingGroupContext,
    InstitutionContext,
    MasterGroupContext,
    OnlinePatientManagementContext,
    PatientBreadcrumbs,
    PatientContext,
    PatientInformation,
    PatientInformationFn,
    PatientSummaryList,
    ProgressButton,
    RouteLoading,
    ValidationResultType,
    usePatientSummary,
    useSnackbar
} from '@ngt/opms';

import Alert from '@material-ui/lab/Alert';

import { faInfoCircle } from '@fortawesome/pro-duotone-svg-icons/faInfoCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { RequestState } from '@ngt/request-utilities';
import { DateTime } from 'luxon';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import { Permission, usePermissionsByIds } from '@ngt/opms-bctapi';
import { useHistory } from 'react-router-dom';
import * as Dtos from '../api/dtos';
import { JsonServiceClient } from '@servicestack/client';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import PatientStatusForm from './form/PatientStatusForm';

/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IPatientSummaryProps {

}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(3, 0),
    },
    buttonGroup: {
        padding: theme.spacing(3, 0, 0, 0),
        textAlign: 'center',

        '& > *': {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1)
        },

        [theme.breakpoints.up('sm')]: {
            textAlign: 'right',
            '& > *': {
                marginLeft: theme.spacing(1),
                marginRight: theme.spacing(0)
            }
        }
    },
    button: {
        height: "fit-content",
        marginLeft: theme.spacing(2),

        '&:first-child': {
            marginLeft: theme.spacing(0)
        }
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "flex-end"
    }
}));

const useEventActionStyles = makeStyles(theme => ({
    button: {
        minWidth: 140,
        borderRadius: 0,
        whiteSpace: 'nowrap',
        '&:first-child': {
            marginLeft: theme.spacing(0)
        }
    },
    tooltip: {
        display: 'flex'
    },
    wrapper: {
        display: 'flex'
    }
}));

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

enum DialogState {
    None = 0,
    Create = 1,
    CreateAndNotify = 2,
    Resend = 3
}

const permissions: Permission[] = [
    Permission.OpmsAdminister,
    Permission.OpmsPatientUpdate
];

const patientCaption: PatientInformationFn = (patient) => (patient as Dtos.Patient)?.initials
const patientStateCaption: PatientInformationFn = (patient) => DateTime.fromISO((patient as Dtos.Patient)?.registrationDate ?? (patient as Dtos.Patient)?.preregistrationDate ?? patient?.enteredDate ?? DateTime.local().toISO({ includeOffset: false })).toFormat('dd/MM/yyyy')

const PatientSummary: React.FunctionComponent<IPatientSummaryProps> = () => {
    const classes = useStyles();

    const { register, handleSubmit, reset } = useForm();

    const [waiverModalOpen, setWaiverModalOpen] = React.useState(false);

    const { enqueueSnackbar } = useSnackbar()
    const eventActionClasses = useEventActionStyles();

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient, actions, saveState } = React.useContext(PatientContext);

    const { patientSummary, loadState: summaryLoadState, validationState, load: summaryLoad, validate: summaryValidate } = usePatientSummary(patient?.id);

    const patientValid = patientSummary?.result === ValidationResultType.Valid;

    const saving = saveState.state === RequestState.Pending || summaryLoadState?.state === RequestState.Pending;

    const updatePatientState = React.useCallback(async (patientStateId: Dtos.PatientStateType, skipPatientEmail?: boolean, patientStatusReason?: string) => {
        return await actions.asyncSave(new Dtos.Patient({ ...patient, previousPatientStateId: patient?.patientStateId, patientStateId: patientStateId, skipEmailingPatient: skipPatientEmail, patientStatusReason: patientStatusReason }))
            .then((patient: Dtos.Patient) => {

                if (patient.patientStateId === Dtos.PatientStateType.Registered as number) {
                    enqueueSnackbar(
                        <>
                            <AlertTitle>
                                Participant Registered
                            </AlertTitle>
                            The participant has been successfully registered to PD-L1 {patient?.treatmentId === (Dtos.TreatmentType.Pdl1PositiveCohort as number) ? 'Positive Cohort and will receive Pembrolizumab and Trastuzumab' : 'Negative Cohort and will receive Pembrolizumab, Trastuzumab, and Capecitabine'}.
                        </>,
                        { variant: 'success' }
                    );
                }
            })
            .catch(() => {

            });
        
    }, [actions, patient, enqueueSnackbar]);

    const onPreRegisterClick = React.useCallback(async () => {
        try {
            await updatePatientState(Dtos.PatientStateType.Preregistered);

            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Participant Pre-registered
                    </AlertTitle>
                    The participant was successfully pre-registered.
                </>,
                { variant: 'success' }
            );
        }
        catch (error) {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Participant Not Pre-registered
                    </AlertTitle>
                    An error occurred while attempting to pre-register the participant.
                </>,
                { variant: 'critical' }
            );
        }
    }, [updatePatientState, enqueueSnackbar]);

    const onRegisterClick = React.useCallback(async () => {
        setWaiverModalOpen(false);

        try {
            await updatePatientState(Dtos.PatientStateType.Registered);
        }
        catch (error) {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Participant Not Registered
                    </AlertTitle>
                    An error occurred while attempting to register the participant.
                </>,
                { variant: 'critical' }
            );
        }
    }, [updatePatientState, setWaiverModalOpen, enqueueSnackbar]);

    const history = useHistory();

    const onWaiverYesClick = React.useCallback((mode: string) => {
        setWaiverModalOpen(false);

        history.push(`/registration/${institution?.code}/${patient?.studyNumber}/staff/1/waiver-form/1/${mode}`);
    }, [setWaiverModalOpen, history, institution, patient]);

    const onWaiverFormViewClick = React.useCallback(() => {
        history.push(`/registration/${institution?.code}/${patient?.studyNumber}/staff/1/waiver-form/1/view`);
    }, [history, institution, patient]);


    const onIneligibleClick = React.useCallback(async () => {
        history.push(`/registration/${institution?.code}/${patient?.studyNumber}/mark-ineligible`);

    }, [updatePatientState, enqueueSnackbar]);

    const onRestoreClick = React.useCallback(async () => {
        try {
            await updatePatientState((patient as Dtos.Patient)?.previousPatientStateId ?? Dtos.PatientStateType.Preregistered);

            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Participant Restored
                    </AlertTitle>
                    The participant was successfully restored.
                </>,
                { variant: 'success' }
            );
        }
        catch (error) {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Participant Not Restored
                    </AlertTitle>
                    An error occurred while attempting to restore the participant.
                </>,
                { variant: 'critical' }
            );
        }
    }, [updatePatientState, enqueueSnackbar, patient]);

    const toggleWaiverModal = React.useCallback(() => {
        setWaiverModalOpen(state => !state);
    }, [setWaiverModalOpen])

    const [
        [
            canAdministerOpms,
            canUpdatePatient
        ],
        permissionLoadState
    ] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, true);

    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);

    const client = onlinePatientManagement.serviceStackClient;

    const [treatments, setTreatments] = React.useState<Dtos.Treatment[]>([]);

    React.useEffect(() => {
        client
            .get(new Dtos.TreatmentGetCollection())
            .then(response => {
                setTreatments(response.treatments);
            })
            .catch((e) => {

            });
    }, [client, setTreatments]);

    const patientState: PatientInformationFn = React.useCallback((patient, patientState) => {
        return (patient as Dtos.Patient)?.patientStateId === (Dtos.PatientStateType.Registered as number) ?
            <>
                {patientState?.name}
                <br />
                {treatments.find(x => x.id === patient?.treatmentId)?.name}
            </>
            :
            patientState?.name;
    }, [treatments]);

    ///////////////////////////////////////////////////////////////////////////
    // (BCTECRF-102) - Event Actions for PatientSummaryList Component - START
    //
    // This new opms implementation was copied from Optima, which 
    // supports event actions on the patient summary page. Tugether
    // currently does not so this was not needed.
    //
    // I have removed the 'useCreateAndNotifyActions' hook used in the code 
    // below to save space. For further reference, see the PatientSummary
    // implementation in Optima.
    ///////////////////////////////////////////////////////////////////////////

    // const hidePreRegistrationActions = (actionPatient: IPatient | null) => !actionPatient || (actionPatient.patientStateId !== Dtos.PatientStateType.NewPatient && actionPatient.patientStateId !== Dtos.PatientStateType.Preregistered);
    // const hideShipmentDetailsActions = (actionPatient: IPatient | null) => !actionPatient || (actionPatient.patientStateId !== Dtos.PatientStateType.Preregistered && actionPatient.patientStateId !== Dtos.PatientStateType.Registered);
    // const hideRegistrationActions = (actionPatient: IPatient | null) => !actionPatient || (actionPatient.patientStateId !== Dtos.PatientStateType.Preregistered && actionPatient.patientStateId !== Dtos.PatientStateType.Registered);
    // const hidePdlTilResultActions = (actionPatient: IPatient | null) => !actionPatient || (actionPatient.patientStateId !== Dtos.PatientStateType.Preregistered && actionPatient.patientStateId !== Dtos.PatientStateType.Registered);
    // const hideStaffActions = (actionPatient: IPatient | null) => !actionPatient || (actionPatient.patientStateId !== Dtos.PatientStateType.NewPatient && actionPatient.patientStateId !== Dtos.PatientStateType.Preregistered && actionPatient.patientStateId !== Dtos.PatientStateType.Registered);
    
    // const preRegistrationActions = useCreateAndNotifyActions(Dtos.PatientStateType.NewPatient, hidePreRegistrationActions, 'Pre-registration', 'Pre-registration', saving, updatePatientState, enqueueSnackbar, eventActionClasses, onlinePatientManagement.serviceStackClient, patient, patientValid);
    // const shipmentDetailsActions = useCreateAndNotifyActions(Dtos.PatientStateType.Preregistered, hideShipmentDetailsActions, 'Shipment Details', 'Shipment Details', saving, updatePatientState, enqueueSnackbar, eventActionClasses, onlinePatientManagement.serviceStackClient, patient, patientValid);
    // const registrationActions = useCreateAndNotifyActions(Dtos.PatientStateType.Preregistered, hideRegistrationActions, 'Registration', 'Registration', saving, updatePatientState, enqueueSnackbar, eventActionClasses, onlinePatientManagement.serviceStackClient, patient, patientValid);
    // const PdlTilResultActions = useCreateAndNotifyActions(Dtos.PatientStateType.Preregistered, hidePdlTilResultActions, 'PD-L1 and TIL Result"', 'PD-L1 and TIL Result"', saving, updatePatientState, enqueueSnackbar, eventActionClasses, onlinePatientManagement.serviceStackClient, patient, patientValid);
    // const staffActions = useCreateAndNotifyActions(Dtos.PatientStateType.NewPatient, hideStaffActions, 'Staff', 'Staff', saving, updatePatientState, enqueueSnackbar, eventActionClasses, onlinePatientManagement.serviceStackClient, patient, patientValid);

    // const eventActionMapping = React.useMemo(() => {
    //     return {
    //         'pre-registration': preRegistrationActions,
    //         'shipment-details': shipmentDetailsActions,
    //         'registration': registrationActions,
    //         'pdl-and-til-result': PdlTilResultActions,
    //         'staff': staffActions
    //     };
    // }, [preRegistrationActions, shipmentDetailsActions, registrationActions, PdlTilResultActions, staffActions]);

    /////////////////////////////////////////////////////////////////////////
    // (BCTECRF-102) - Event Actions for PatientSummaryList Component - END
    /////////////////////////////////////////////////////////////////////////

    if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }

    const patientError = patientSummary?.result === ValidationResultType.Error;

    const canPreRegister = !saving && (patientValid || ((patientSummary?.result ?? ValidationResultType.Critical) <= ValidationResultType.Error && canAdministerOpms));

    const canRegister = !saving && (patientValid || ((patientSummary?.result ?? ValidationResultType.Critical) <= ValidationResultType.Error && canAdministerOpms));

    return (
        <>
            <PatientBreadcrumbs />
            <div
                className={classes.container}
            >
                <PatientInformation
                    patientCaption={patientCaption}
                    patientStateText={patientState}
                    patientStateCaption={patientStateCaption}
                />

                <Box className={classes.title}>
                    <Box>
                        <Typography
                            variant="h1"
                            color="secondary"
                        >
                            Participant Summary
                        </Typography>
                    </Box>
                    <Box>
                        {
                            ((patient as Dtos.Patient)?.preregisteredByWaiver === true || (patient as Dtos.Patient)?.registeredByWaiver === true) && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    component="a"
                                    className={classes.button}
                                    onClick={onWaiverFormViewClick}
                                >
                                    Waiver Form
                                </Button>
                            )
                        }
                        {
                            patient?.patientStateId === Dtos.PatientStateType.Registered  && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    component="a"
                                    className={classes.button}
                                    href={`/${patient?.id}/forms-due-schedule`}
                                >
                                    Forms Due Schedule
                                </Button>
                            )
                        }
                    </Box>
                </Box>

                {
                    patient?.patientStatusReason && 
                    (
                        <Alert
                            icon={<FontAwesomeIcon icon={faInfoCircle as IconDefinition} fixedWidth />}
                            severity="info"
                        >
                            <AlertTitle>
                                <strong>
                                    Patient Status
                                </strong>
                            </AlertTitle>
                            <Typography style={{whiteSpace: "pre-wrap"}}>
                                {patient?.patientStatusReason}
                            </Typography>
                        </Alert>
                    )
                    }

                <PatientSummaryList 
                    patient={patient ?? undefined}
                    patientSummary={patientSummary}
                    loadState={summaryLoadState}
                    validationState={validationState}
                />

                {
                    patient?.patientStateId === Dtos.PatientStateType.NewPatient && !patientValid && (
                        <Alert
                            icon={<FontAwesomeIcon icon={faInfoCircle as IconDefinition} fixedWidth />}
                            severity="info"
                        >
                            <AlertTitle>
                                <strong>
                                    Note
                                </strong>
                            </AlertTitle>
                            <Typography>
                                Complete the Pre-registration forms to continue participant registration.
                            </Typography>
                        </Alert>
                    )
                }

                {
                    patient?.patientStateId === Dtos.PatientStateType.NewPatient && patientValid && (
                        <Alert
                            icon={<FontAwesomeIcon icon={faInfoCircle as IconDefinition} fixedWidth />}
                            severity="info"
                        >
                            <AlertTitle>
                                <strong>
                                    Note
                                </strong>
                            </AlertTitle>
                            <Typography>
                                All pre-registration forms have been completed. Please click PRE-REGISTER to complete pre-registration.
                            </Typography>
                        </Alert>
                    )
                }

                <Dialog
                    open={waiverModalOpen}
                    onClose={toggleWaiverModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{patient?.patientStateId === Dtos.PatientStateType.NewPatient ? 'Pre-register' : 'Register'} patient by waiver?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            The patient has outstanding {patientError ? 'errors' : 'ineligibility warnings'} &mdash; waiver is required.
                            <br /><br />
                            Do you want to proceed?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={toggleWaiverModal} color="secondary">
                            No
                        </Button>
                        <Button onClick={() => onWaiverYesClick(patient?.patientStateId === Dtos.PatientStateType.NewPatient ? 'pre-register' : 'register')} color="primary" variant="contained" autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
                <div
                    className={classes.buttonGroup}
                >
                    {
                        patient?.patientStateId === Dtos.PatientStateType.NewPatient && canUpdatePatient && patientValid && (
                            <ProgressButton
                                loading={saving}
                                variant="contained"
                                color="primary"
                                onClick={onPreRegisterClick}
                                disabled={!canPreRegister}
                            >
                                Pre-register
                            </ProgressButton>
                        )
                    }
                    {
                        patient?.patientStateId === Dtos.PatientStateType.NewPatient && canUpdatePatient && !patientValid && (
                            <ProgressButton
                                loading={saving}
                                variant="contained"
                                color="primary"
                                onClick={toggleWaiverModal}
                                disabled={!canPreRegister}
                            >
                                Pre-register
                            </ProgressButton>
                        )
                    }
                    {
                        patient?.patientStateId === Dtos.PatientStateType.Preregistered && canUpdatePatient && patientValid && (
                            <ProgressButton
                                loading={saving}
                                variant="contained"
                                color="primary"
                                onClick={onRegisterClick}
                                disabled={!canRegister}
                            >
                                Register
                            </ProgressButton>
                        )
                    }
                    {
                        patient?.patientStateId === Dtos.PatientStateType.Preregistered && canUpdatePatient && !patientValid && (
                            <ProgressButton
                                loading={saving}
                                variant="contained"
                                color="primary"
                                onClick={toggleWaiverModal}
                                disabled={!canRegister}
                            >
                                Register
                            </ProgressButton>
                        )
                    }
                    {
                        patient?.patientStateId !== Dtos.PatientStateType.Ineligible && patient?.patientStateId !== Dtos.PatientStateType.Registered && canAdministerOpms && (
                            <ProgressButton
                                loading={saving}
                                variant="contained"
                                color="primary"
                                onClick={onIneligibleClick}
                            >
                                Mark As Ineligible
                            </ProgressButton>
                        )
                    }
                    {
                        patient?.patientStateId === Dtos.PatientStateType.Ineligible && canAdministerOpms && (
                            <ProgressButton
                                loading={saving}
                                variant="contained"
                                color="primary"
                                onClick={onIneligibleClick}
                            >
                                Failed Eligibility Criteria
                            </ProgressButton>
                        )
                    }
                    {
                        patient?.patientStateId === Dtos.PatientStateType.Ineligible && canAdministerOpms && (
                            <ProgressButton
                                loading={saving}
                                variant="contained"
                                color="primary"
                                onClick={onRestoreClick}
                            >
                                Restore Patient
                            </ProgressButton>
                        )
                    }
                </div>
            </div>
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default PatientSummary;
