/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';


/**
 * Used for the basic page layout.
 */
import {
    CollaboratingGroupContext,
    CrfCondition,
    CrfForm,
    FieldGroup,
    FormBreadcrumbs,
    FormContext,
    FormGrid,
    ICrfConditionParameters,
    IFormActions,
    IFormGridCell,
    IFormState,
    Input,
    InstitutionContext,
    KeyboardDatePicker,
    MasterGroupContext,
    OnlinePatientManagementContext,
    PatientContext,
    RouteLoading,
    Select,
    SubmitButton,
    Text,

    TextArea,
    getParentPropertyPath,
    useFormActions,
    useFormDefinitions,
    usePatientValidationByStudyNumber,
    useSnackbar
} from '@ngt/opms';

import { usePermissionsByIds } from '@ngt/opms-bctapi';

import { RequestState } from '@ngt/request-utilities';

import { Button, Typography, makeStyles } from '@material-ui/core';


/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import AlertTitle from '@material-ui/lab/AlertTitle';
import { cloneDeep, get } from 'lodash-es';
import * as pluralize from 'pluralize';
import { useHistory, useParams } from 'react-router-dom';
import * as Dtos from '../../api/dtos';
import WaiverSourceDocuments from '../../components/WaiverSourceDocuments';


/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IWaiverFormParams {
    institutionCode?: string,
    patientStudyNumber?: string;
    eventDefinitionCode?: string;
    eventRepeat?: string;
    formDefinitionCode?: string;
    formRepeat?: string;
    mode?: string;
}

interface IWaiverFormProps {
}

interface IWaiverFormFieldsProps {
    formEnabled: boolean
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    subHeading: {
        padding: theme.spacing(3, 3, 3, 3)
    },
    buttonGroup: {
        padding: theme.spacing(3),
        textAlign: 'center',

        '& > *': {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1)
        },

        [theme.breakpoints.up('sm')]: {
            textAlign: 'right',
            '& > *': {
                marginLeft: theme.spacing(1),
                marginRight: theme.spacing(0)
            }
        }
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */


/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Dtos.Permission[] = [
    Dtos.Permission.OpmsCrfUpdate,
    Dtos.Permission.OpmsAdminister
];

const isApprovedByOther = ({ lookups, formState, parentFieldName }: ICrfConditionParameters<Dtos.Waiver>) => {
    // parent field name will be the cell in the grid, we want to get the row, so get the parent of the parent.
    const parentPropertyPath = getParentPropertyPath(parentFieldName);

    if (!parentPropertyPath) {
        return false;
    }

    const row: Dtos.Waiver = get(formState?.values, parentPropertyPath);

    if (!row) {
        return false;
    }

    return row.approvedBy === Dtos.LookupApprovedByType.Other;
};


const waiversColumn: Array<IFormGridCell<Dtos.Waiver>> = [
    {
        name: 'waiverType',
        width: 200,
        content: (
            <Input
                component={Select}
                defaultValue={""}
                disabled
            />
        )
    },
    {
        name: 'approvedBy',
        content: (
            <Input
                component={Select}
                defaultValue={""}
            />
        )
    },
    {
        name: 'approvedByOther',
        content: (
            <CrfCondition
                type={Dtos.Waiver}
                condition={isApprovedByOther}
                mode="Enable"
                subscription={{ values: true }}
            >
                <Input
                    component={Text}
                />
            </CrfCondition>
        )
    },
    {
        name: 'dateWaiverGranted',
        width: 220,
        content: (
            <Input
                component={KeyboardDatePicker}
            />
        )
    },
    {
        name: 'reason',
        content: (
            <Input
                component={TextArea}
            />
        )
    },
];

const failedEligibilityCriteriaColumns: Array<IFormGridCell<Dtos.FailedEligibilityCriteria>> = [
    {
        name: 'waiverType',
        width: 200,
        content: (
            <Input
                component={Select}
                defaultValue={""}
                disabled
            />
        )
    },
    {
        name: 'formName',
        content: (
            <Input
                component={TextArea}
                disabled
            />
        )
    },
    {
        name: 'fieldName',
        content: (
            <Input
                component={TextArea}
                disabled
            />
        )
    },
    {
        name: 'details',
        content: (
            <Input
                component={TextArea}
                disabled
            />
        )
    },
];

const WaiverFormFields: React.FunctionComponent<IWaiverFormFieldsProps> = ({
    formEnabled
}) => {
    const classes = useStyles();

    const { patient } = React.useContext(PatientContext);

    const { setFieldValue } = useFormActions();

    const [patientValidation, , ] = usePatientValidationByStudyNumber(patient?.studyNumber!, false);

    const [formDefinitions, , ] = useFormDefinitions(false);

    const { form } = React.useContext(FormContext);

    const typedForm = form as Dtos.WaiverForm;

    React.useEffect(() => {
        if (formEnabled) {
            //generate waiver

            let waiver = {
                waiverType: patient?.patientStateId === Dtos.PatientStateType.NewPatient ? Dtos.LookupWaiverType.Preregistration : Dtos.LookupWaiverType.Registration
            } as Dtos.Waiver

            setFieldValue(`waivers`, [...cloneDeep(typedForm.waivers ?? []), waiver]);


            //generate failed eligibility criteria

            let failedEligibilityCriteriaArr: Dtos.FailedEligibilityCriteria[] = [];

            patientValidation?.eventResults?.map(er => er.formResults)?.reduce((a, b) => a.concat(b))
                .filter(fr => fr.formDefinitionId !== Dtos.FormDefinitionType.WaiverForm &&
                    fr.formDefinitionId !== Dtos.FormDefinitionType.PatientStatusForm)
                .forEach(fr => {
                    if (fr?.errors?.filter(error => (error.type ?? 0) > Dtos.ValidationErrorType.Warning)?.length > 0) {

                        const formDefinition = formDefinitions?.find(fd => fd.id === fr.formDefinitionId);

                        fr?.errors?.filter(error => (error.type ?? 0) > Dtos.ValidationErrorType.Warning)?.forEach(error => {
                            let failedEligibilityCriteria: Dtos.FailedEligibilityCriteria = {} as Dtos.FailedEligibilityCriteria;

                            let fieldName = "";

                            if (!error.property.match(/\[\d\]\./g)) {
                                fieldName = formDefinition?.properties?.find(p => p.propertyName === error.property)?.label ?? "";
                            } else {
                                let subformPropertyName = error?.property.split(/\[\d\]\./g)[0];
                                let propertyName = error?.property.split(/\[\d\]\./g)[1];

                                fieldName = formDefinition?.subformProperties[subformPropertyName].find(p => p.propertyName === propertyName)?.label ?? "";
                            }

                            failedEligibilityCriteria.waiverType = patient?.patientStateId === Dtos.PatientStateType.NewPatient ? Dtos.LookupWaiverType.Preregistration : Dtos.LookupWaiverType.Registration;
                            failedEligibilityCriteria.formName = (formDefinition?.name ?? "").replace(/<.*?>/g, "");
                            failedEligibilityCriteria.fieldName = fieldName.replace(/<.*?>/g, "");
                            failedEligibilityCriteria.details = error?.detailedMessage.replace(/<.*?>/g, "");

                            failedEligibilityCriteriaArr.push(failedEligibilityCriteria);
                        });
                    }
                });

            setFieldValue(`failedEligibilityCriteria`, [...(typedForm.failedEligibilityCriteria ?? []), ...failedEligibilityCriteriaArr]);
        }
       
    }, [patientValidation, setFieldValue, formDefinitions, form, formEnabled, patient, typedForm]);

    return <>
        <FieldGroup>
            <Typography
                variant="h2"
                color="primary"
                className={classes.subHeading}
            >
                Waivers
            </Typography>
            <FormGrid
                name="waivers"
                columns={waiversColumn}
                type={Dtos.Waiver}
                allowDelete={() => false}
                allowAdd={false}
            />
        </FieldGroup>

        <FieldGroup>
            <Typography
                variant="h2"
                color="primary"
                className={classes.subHeading}
            >
                Failed Eligibility Criteria
            </Typography>
            <FormGrid
                type={Dtos.FailedEligibilityCriteria}
                name="failedEligibilityCriteria"
                columns={failedEligibilityCriteriaColumns}
                allowDelete={() => false}
                allowAdd={false}
            />
        </FieldGroup>

        <WaiverSourceDocuments />
    </>
}

const WaiverForm: React.FunctionComponent<IWaiverFormProps> = () => {
    const classes = useStyles();

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);

    const params = useParams<IWaiverFormParams>();

    const [[canUpdateCrf, canAdministerOpms], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, true);

    const { enqueueSnackbar } = useSnackbar();

    const allowSubmit = React.useCallback(async ({ errors }: IFormState<Dtos.WaiverForm, Dtos.IValidationError>, formActions: IFormActions<Dtos.WaiverForm, Dtos.IValidationError>) => {
        if (!errors) {
            return true;
        }

        return !Object.keys(errors).some(key => errors[key] && errors[key].some(e => (e.type ?? Dtos.ValidationErrorType.Normal) >= Dtos.ValidationErrorType.Normal));
    }, []);

    const onFormSubmitValidationFailure = React.useCallback(async ({ errors }: IFormState<Dtos.WaiverForm, Dtos.IValidationError>, validationError: boolean) => {
        if (validationError) {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Form Not Saved
                    </AlertTitle>
                    An error occurred while attempting to validate the form.
                </>,
                { variant: 'critical' }
            );
        }
        else {
            const criticalErrors = Object
                .keys(errors)
                .reduce((array: Dtos.IValidationError[], key: string) => {
                    const propertyErrors = errors[key]?.reduce((propertyArray: Dtos.IValidationError[], e: Dtos.IValidationError) => {
                        if ((e.type ?? Dtos.ValidationErrorType.Normal) >= Dtos.ValidationErrorType.Normal) {
                            return [...propertyArray, e]
                        }

                        return propertyArray;
                    }, [])

                    return [...array, ...propertyErrors]
                }, []);

            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Form Not Saved
                    </AlertTitle>
                    Please correct the {criticalErrors.length} blocking {pluralize('error', criticalErrors.length)} and submit the form again.
                </>,
                { variant: 'critical' }
            );
        }
    }, [enqueueSnackbar]);

    const history = useHistory();

    const onFormCancel = React.useCallback(() => {
        history.push(`/registration/${institution?.code}/${patient?.studyNumber}`)
    }, [history, institution, patient]);

    const [patientValidation, patientValidationLoadState, ] = usePatientValidationByStudyNumber(patient?.studyNumber!, true);

    const [, formDefinitionsLoadState, ] = useFormDefinitions(true);

    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);

    const afterSave = React.useCallback(async (institution?: Dtos.Institution | null, patient?: Dtos.Patient | null, event?: Dtos.IEvent | null, form?: Dtos.WaiverForm | null) => {
        history.push(`/registration/${institution?.code}/${patient?.studyNumber}`);

        const patientToUse = (await onlinePatientManagement.serviceStackClient.get(new Dtos.PatientGetSingleById({ id: patient?.id }))).patient

        if (patientToUse?.patientStateId === Dtos.PatientStateType.Preregistered) {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Participant Pre-registered
                    </AlertTitle>
                    The participant was successfully pre-registered.
                </>,
                { variant: 'success' }
            );
        } else if (patientToUse?.patientStateId === Dtos.PatientStateType.Registered) {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Participant Registered
                    </AlertTitle>
                    The participant has been successfully registered to PD-L1 {patientToUse?.treatmentId === (Dtos.TreatmentType.Pdl1PositiveCohort as number) ? 'Positive Cohort and will receive Pembrolizumab and Trastuzumab' : 'Negative Cohort and will receive Pembrolizumab, Trastuzumab, and Capecitabine'}.
                </>,
                { variant: 'success' }
            );
        }    
    }, [enqueueSnackbar, onlinePatientManagement, history]);

    const formEnabled = React.useMemo(() => {
        if (canAdministerOpms && (patientValidation?.result ?? Dtos.ValidationResultType.Critical) <= Dtos.ValidationResultType.Error) {
            if
            (
                (params?.mode === "pre-register" && patient?.patientStateId === Dtos.PatientStateType.NewPatient) ||
                (params?.mode === "register" && patient?.patientStateId === Dtos.PatientStateType.Preregistered)
            )
            {
                return true;
            }
        }

        return false;
    }, [patient, canAdministerOpms, patientValidation, params]);

    if (
        permissionLoadState.state === RequestState.None ||
        permissionLoadState.state === RequestState.Pending ||
        patientValidationLoadState.state === RequestState.None ||
        patientValidationLoadState.state === RequestState.Pending ||
        formDefinitionsLoadState.state === RequestState.None ||
        formDefinitionsLoadState.state === RequestState.Pending
    ) {
        return (
            <RouteLoading />
        );
    }

    return (
        <>
            <FormBreadcrumbs />
            <CrfForm
                formType={Dtos.WaiverForm}
                validateOn="onChange"
                canEdit={canUpdateCrf && formEnabled}
                allowSubmit={allowSubmit}
                onFormSubmitValidationFailure={onFormSubmitValidationFailure}
                afterFormSave={afterSave}
                afterFormSaveAndReturn={afterSave}
                hideSubmit
                hideSubmitAndReturn
                hideCancel
            >
                <WaiverFormFields formEnabled={formEnabled} />

                {
                    !!formEnabled && (
                        <div
                            className={classes.buttonGroup}
                        >
                            <SubmitButton
                                variant="contained"
                                color="primary"
                            >
                                {patient?.patientStateId === Dtos.PatientStateType.NewPatient ? "Pre-register" : "Register"} by Waiver
                            </SubmitButton>

                            <Button
                                variant="contained"
                                color="primary"
                                onClick={onFormCancel}
                            >
                                Cancel
                            </Button>
                        </div>
                    )
                }
            </CrfForm>
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default WaiverForm;
