/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';

import { cloneDeep, get } from 'lodash-es';

import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, InputAdornment, Typography, makeStyles } from '@material-ui/core';


/**
 * Used for the basic page layout.
 */
import {
    FormContext,
    Checkbox,
    CollaboratingGroupContext,
    ConditionContext,
    CrfCondition,
    CrfDialogForm,
    CrfForm,
    Field,
    FieldGroup,
    FieldProvider,
    FormBreadcrumbs,
    FormGrid,
    FormOptionsContext,
    GetFieldLookupItem,
    ICrfConditionParameters,
    IFormActions,
    IFormGridCell,
    IFormState,
    IFormSubscription,
    IValidationError,
    InstitutionContext,
    KeyboardDatePicker,
    MasterGroupContext,
    Numeric,
    PatientContext,
    PatientValidationContext,
    ProgressButton,
    RouteLoading,
    Select,
    Text,
    ToggleButtonGroup,
    useFormActions,
    useFormByCodes,
    useFormState,
    useRelatedField,
    useSnackbar,
    OnlinePatientManagementContext
} from '@ngt/opms';

import { usePermissionsByIds } from '@ngt/opms-bctapi';

import { RequestState } from '@ngt/request-utilities';




/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import AlertTitle from '@material-ui/lab/AlertTitle';
import { DateTime } from 'luxon';
import * as pluralize from 'pluralize';
import { useHistory } from 'react-router-dom';
import * as Dtos from '../../api/dtos';
import DialogFormResolver from '../../components/DialogFormResolver';
import InputDisplay from '../../components/InputDisplay';
import SourceDocuments from '../../components/SourceDocuments';
import { THREE_DIGIT_POSITIVE_INTEGER } from './General';

/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IPdlAndTilResultProps {
}

interface IPdlAndTilResultsTblProps {
    dialogOpen: boolean;
    showDialog: (show: boolean) => void;
}
interface IInsufficientTissueSectionProps {
    showDialog: (show: boolean) => void;
}

interface ITableContext {
    index: number | undefined;
    setIndex: (index: number | undefined) => void;
    openPdl1ResultDialog: boolean;
    setOpenPdl1ResultDialog: (open: boolean) => void;
    openTumourSampleDialog: boolean;
    setOpenTumourSampleDialog: (open: boolean) => void;
}


/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(0, 3, 3)
    },
    buttonGroup: {
        padding: theme.spacing(0, 3, 3),
        textAlign: 'center',

        '& > *': {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1)
        },

        [theme.breakpoints.up('sm')]: {
            textAlign: 'right',
            '& > *': {
                marginLeft: theme.spacing(1),
                marginRight: theme.spacing(0)
            }
        }
    },
    subHeading: {
        padding: theme.spacing(3, 3, 3, 3)
    },
    instruction: {
        padding: theme.spacing(0, 3),
        fontSize: '1rem'
    },
    sdInstruction: {
        padding: theme.spacing(2,3),
    },
    header: {
        fontSize: '1rem',
        fontStyle: 'bold'
    },
    update: {
        color: theme.palette.info.main
    },
    dialogButton: {
        textAlign: 'right',
        padding: theme.spacing(3),

        '& > :first-child': {
            marginRight: theme.spacing(1),
        }
    },
    dialogTitle: {
        color: theme.palette.primary.main,
        '& *': {
            fontSize: '1.25rem !important'
        }
    },
    dialogFormTitle: {
        color: theme.palette.secondary.main,
        borderBottom: 'solid rgb(224, 224, 224) 1px',

        '& *': {
            fontSize: '1.5rem !important'
        }
    },
    dialogActions: {
        margin: theme.spacing(0, 1)
    },
    return: {
        display: 'flex',
        justifyContent: 'flex-end',
        margin: theme.spacing(3, 3, 0)
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

export const getReasonValue = (data: any) => {
    switch (data) {
        case Dtos.LookupNotSuitableReasonType.InsufficientQuantity: { return 'Insufficient quantity of tumour cells in submitted tumour block.'; }
        case Dtos.LookupNotSuitableReasonType.Other: { return 'Other - Specify'; }
        default: { return ''; }
    }
}

export const getYesNoValue = (data: any) => {
    switch (data) {
        case Dtos.LookupYesNoType.Yes: { return 'Yes'; }
        case Dtos.LookupYesNoType.No: { return 'No'; }
        default: { return ''; }
    }
}


/*
 * ----------------------------------------------------------------------------------
 * Functions
 * ----------------------------------------------------------------------------------
 */

//const isSuitableForPdlAssayAndTilCount = ({ lookups, formState, parentFieldName }: ICrfConditionParameters<Dtos.PdlAndTilResultForm>) => {
//    if (!parentFieldName) {
//        return false;
//    }

//    const row: Dtos.PdlAndTilResult = get(formState?.values, parentFieldName);

//    if (!row) {
//        return false;
//    }

//    return row.tumourBlockSuitable === Dtos.LookupYesNoType.Yes as number;
//};

//const isNotSuitableForPdlAssayAndTilCount = ({ lookups, formState, parentFieldName }: ICrfConditionParameters<Dtos.PdlAndTilResultForm>) => {
//    if (!parentFieldName) {
//        return false;
//    }

//    const row: Dtos.PdlAndTilResult = get(formState?.values, parentFieldName);

//    if (!row) {
//        return false;
//    }

//    return row.tumourBlockSuitable === Dtos.LookupYesNoType.No as number;
//};

//const isNotSuitableForPdlAssayAndTilCountReasonOther = ({ lookups, formState, parentFieldName }: ICrfConditionParameters<Dtos.PdlAndTilResultForm>) => {
//    if (!parentFieldName) {
//        return false;
//    }

//    const row: Dtos.PdlAndTilResult = get(formState?.values, parentFieldName);

//    if (!row) {
//        return false;
//    }

//    return row.tumourBlockNotSuitableReason === Dtos.LookupNotSuitableReasonType.Other as number;
//};

const isPdlAssaySuccessful = ({ lookups, formState, parentFieldName }: ICrfConditionParameters<Dtos.PdlAndTilResultForm>) => {
    if (!parentFieldName) {
        return false;
    }

    const row: Dtos.PdlAndTilResult = get(formState?.values, parentFieldName);

    if (!row) {
        return false;
    }

    return row.pdlAssaySuccessful === Dtos.LookupYesNoType.Yes as number;
};

// const isPdlAssaySuccessfulAndHasResult = ({ lookups, formState, parentFieldName }: ICrfConditionParameters<Dtos.PdlAndTilResultForm>) => {
//     if (!parentFieldName) {
//         return false;
//     }

//     const row: Dtos.PdlAndTilResult = get(formState?.values, parentFieldName);

//     if (!row) {
//         return false;
//     }

//     return row.pdlAssaySuccessful === Dtos.LookupYesNoType.Yes as number && !row.pdlAssayResultNoResult;
// };

const isPdlAssayNotSuccessful = ({ lookups, formState, parentFieldName }: ICrfConditionParameters<Dtos.PdlAndTilResultForm>) => {
    if (!parentFieldName) {
        return false;
    }

    const row: Dtos.PdlAndTilResult = get(formState?.values, parentFieldName);

    if (!row) {
        return false;
    }

    return row.pdlAssaySuccessful === Dtos.LookupYesNoType.No as number;
};

const isNotRequiredNotChecked = ({ lookups, formState, parentFieldName }: ICrfConditionParameters<Dtos.PdlAndTilResultForm>) => {
    if (!parentFieldName) {
        return false;
    }

    const row: Dtos.PdlAndTilResult = get(formState?.values, parentFieldName);

    if (!row) {
        return false;
    }

    return row.notRequired !== true;
};

const isPdlAssayNotSuccessfulResubmission = ({ formState, parentFieldName }: ICrfConditionParameters<Dtos.PdlAndTilResultForm>) => {
    if (!parentFieldName) {
        return false;
    }

    const row: Dtos.PdlAndTilResult = get(formState?.values, parentFieldName);

    if (!row) {
        return false;
    }

    return row.pdlAssaySuccessful === Dtos.LookupYesNoType.No as number && row.sampleResubmitted === true;
};


const isPdlAssayNotSuccessfulReasonOther = ({ lookups, formState, parentFieldName }: ICrfConditionParameters<Dtos.PdlAndTilResultForm>) => {
    if (!parentFieldName) {
        return false;
    }

    const row: Dtos.PdlAndTilResult = get(formState?.values, parentFieldName);

    if (!row) {
        return false;
    }

    return row.pdlAssayNotSuccessfulReason === Dtos.LookupNotSuitableReasonType.Other as number;
};

const isTilCountSuccessful = ({ lookups, formState, parentFieldName }: ICrfConditionParameters<Dtos.PdlAndTilResultForm>) => {
    if (!parentFieldName) {
        return false;
    }

    const row: Dtos.PdlAndTilResult = get(formState?.values, parentFieldName);

    if (!row) {
        return false;
    }

    return row.tilCountSuccessful === Dtos.LookupYesNoType.Yes as number;
};

// const isTilCountSuccessfulAndHasResult = ({ lookups, formState, parentFieldName }: ICrfConditionParameters<Dtos.PdlAndTilResultForm>) => {
//     if (!parentFieldName) {
//         return false;
//     }

//     const row: Dtos.PdlAndTilResult = get(formState?.values, parentFieldName);

//     if (!row) {
//         return false;
//     }

//     return row.tilCountSuccessful === Dtos.LookupYesNoType.Yes as number && !row.tilCountNoResult;
// };

const isTilCountNotSuccessful = ({ lookups, formState, parentFieldName }: ICrfConditionParameters<Dtos.PdlAndTilResultForm>) => {
    if (!parentFieldName) {
        return false;
    }

    const row: Dtos.PdlAndTilResult = get(formState?.values, parentFieldName);

    if (!row) {
        return false;
    }

    return row.tilCountSuccessful === Dtos.LookupYesNoType.No as number;
};

const isTilCountNotSuccessfulResubmission = ({ lookups, formState, parentFieldName }: ICrfConditionParameters<Dtos.PdlAndTilResultForm>) => {
    if (!parentFieldName) {
        return false;
    }

    const row: Dtos.PdlAndTilResult = get(formState?.values, parentFieldName);

    if (!row) {
        return false;
    }

    return row.tilCountSuccessful === Dtos.LookupYesNoType.No as number && row.sampleResubmitted === true;
};

const isTilCountNotSuccessfulReasonOther = ({ lookups, formState, parentFieldName }: ICrfConditionParameters<Dtos.PdlAndTilResultForm>) => {
    if (!parentFieldName) {
        return false;
    }

    const row: Dtos.PdlAndTilResult = get(formState?.values, parentFieldName);

    if (!row) {
        return false;
    }

    return row.tilCountNotSuccessfulReason === Dtos.LookupNotSuitableReasonType.Other as number;
};

const isNewExpectedDateSpecified = ({ lookups, formState, parentFieldName }: ICrfConditionParameters<Dtos.PdlAndTilResultForm>) => {
    if (!parentFieldName) {
        return false;
    }

    const row: Dtos.PdlAndTilResult = get(formState?.values, parentFieldName);

    if (!row) {
        return false;
    }

    return row.specifyNewExpectedDate === true;
};

const isSampleSiteOther = ({ lookups, formState, parentFieldName }: ICrfConditionParameters<Dtos.TumourBlock>) => {
    // parent field name will be the cell in the grid, we want to get the row, so get the parent of the parent.
    //const parentPropertyPath = getParentPropertyPath(parentFieldName);

    if (!parentFieldName) {
        return false;
    }

    const row: Dtos.TumourBlock = get(formState?.values, parentFieldName);

    if (!row) {
        return false;
    }

    return row.sampleSite === Dtos.LookupSampleSiteType.Other;
};

const isSampleSlides = ({ lookups, formState, parentFieldName }: ICrfConditionParameters<Dtos.TumourBlock>) => {
    // parent field name will be the cell in the grid, we want to get the row, so get the parent of the parent.
    //const parentPropertyPath = getParentPropertyPath(parentFieldName);

    if (!parentFieldName) {
        return false;
    }

    const row: Dtos.TumourBlock = get(formState?.values, parentFieldName);

    if (!row) {
        return false;
    }

    return row.ffpeBlockOrSlides === Dtos.LookupFfpeType.Slides;
};

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const pdlAndTilResultsColumns: Array<IFormGridCell<Dtos.PdlAndTilResult>> = [
    {
        name: 'notRequired',
        header: <Typography>Not Required</Typography>,
        minWidth: 150,
        maxWidth: 150,
        width: 150,
        content: (
            <InputDisplay
                convert={(notRequired: boolean) => 
                    notRequired ? 'Not Required' : ''
                } />
        )
    },
    {
        name: 'tumourBlockId',
        header: <Typography>Tumour Sample ID</Typography>,
        minWidth: 150,
        maxWidth: 150,
        width: 150,
        content: (
            <InputDisplay />
        )
    },
    {
        name: 'centralLabId',
        hidden: true
    },
    {
        name: 'dateReceivedAtCentralLab',
        header: <Typography>Date Received at Central Lab</Typography>,
        minWidth: 125,
        maxWidth: 125,
        width: 125,
        content: (
            <InputDisplay
                convert={(fieldValue: any) => fieldValue ? DateTime.fromISO(fieldValue).toFormat('dd/MM/yyyy') : null}
            />
        )
    },
    {
        name: 'dateExpected',
        hidden: true
    },
    {
        name: 'newExpectedDate',
        hidden: true
    },
    {
        name: 'newExpectedDateReason',
        hidden: true
    },
    {
        name: 'datePdlStainingCompleted',
        header: <Typography>Date PD-L1 staining completed</Typography>,
        minWidth: 125,
        maxWidth: 125,
        width: 125,
        content: (
            <InputDisplay
                convert={(fieldValue: any) => fieldValue ? DateTime.fromISO(fieldValue).toFormat('dd/MM/yyyy') : null}
            />
        )
    },
    {
        name: 'dateTilStainingCompleted',
        header: <Typography>Date TILs staining completed</Typography>,
        minWidth: 125,
        maxWidth: 125,
        width: 125,
        content: (
            <InputDisplay
                convert={(fieldValue: any) => fieldValue ? DateTime.fromISO(fieldValue).toFormat('dd/MM/yyyy') : null}
            />
        )
    },
    {
        name: 'datePdlAssayAndTilCountReported',
        header: <Typography>Date PD-L1 assay and TIL count reported</Typography>,
        minWidth: 125,
        maxWidth: 125,
        width: 125,
        content: (
            <InputDisplay
                convert={(fieldValue: any) => fieldValue ? DateTime.fromISO(fieldValue).toFormat('dd/MM/yyyy') : null}
            />
        )
    },
    {
        name: 'pdlAssaySuccessful',
        header: <Typography>PD-L1 assay successful</Typography>,
        minWidth: 100,
        maxWidth: 100,
        width: 100,
        content: (
            <InputDisplay
                convert={(fieldValue: any, lookups) => GetFieldLookupItem(lookups, `pdlAndTilResults.pdlAssaySuccessful`, fieldValue)?.value}
            />
        )
    },
    {
        name: 'pdlAssayResult',
        header: <Typography>PD-L1 assay result</Typography>,
        minWidth: 125,
        maxWidth: 125,
        width: 125,
        content: (
            <InputDisplay
                convert={(fieldValue: any, lookups) => GetFieldLookupItem(lookups, `pdlAndTilResults.pdlAssayResult`, fieldValue)?.value}
                specifyName="pdlAssayResultNoResult"
                specifyConvert={(fieldValue: any, lookups) => fieldValue === true ? "No Result" : ""}
            />
        )
    },
    {
        name: 'pdlAssayNotSuccessfulReason',
        hidden: true
    },
    {
        name: 'pdlAssayNotSuccessfulReasonSpecify',
        hidden: true
    },
    {
        name: 'tilCountSuccessful',
        header: <Typography>TILs count successful</Typography>,
        minWidth: 100,
        maxWidth: 100,
        width: 100,
        content: (
            <InputDisplay
                convert={(fieldValue: any, lookups) => GetFieldLookupItem(lookups, `pdlAndTilResults.tilCountSuccessful`, fieldValue)?.value}
            />
        )
    },
    {
        name: 'tilCountNotSuccessfulReason',
        hidden: true
    },
    {
        name: 'tilCountNotSuccessfulReasonSpecify',
        hidden: true
    },

    {
        name: 'tilCountResult',
        minWidth: 125,
        maxWidth: 125,
        width: 125,
        content: (
            <InputDisplay
                convert={(fieldValue: any, lookups) => fieldValue != null ? fieldValue + '%' : null}
                specifyName="tilCountNoResult"
                specifyConvert={(fieldValue: any, lookups) => fieldValue ? "No Result" : ""}
            />
        )
    },
];

const permissions: Dtos.Permission[] = [
    Dtos.Permission.OpmsCrfUpdate,
    Dtos.Permission.OpmsAdminister,
    Dtos.Permission.PathologyTestAdminister
];

const TableContext = React.createContext<ITableContext>({
    setIndex: () => { },
    index: undefined,
    openPdl1ResultDialog: false,
    setOpenPdl1ResultDialog: () => { },
    openTumourSampleDialog: false,
    setOpenTumourSampleDialog: () => { }
});

const subscription = { submitting: true }

const PdlAndTilResultsTbl: React.FunctionComponent = () => {
    const classes = useStyles();

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);

    const [[, , canAdministerPathologyTest], ] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, false, false);

    const { getFieldValue, setFieldValue, submit, getErrors } = useFormActions();

    const { submitting } = useFormState(subscription);

    const [previousValue, setPreviousValue] = React.useState<Dtos.PdlAndTilResult | undefined>(undefined);

    const { index, setIndex, openPdl1ResultDialog, setOpenPdl1ResultDialog } = React.useContext(TableContext);

    React.useEffect(() => {
    }, [index, setPreviousValue, getFieldValue]);

    const onCancel = React.useCallback(() => {
        setFieldValue(`pdlAndTilResults[${index}]`, previousValue);
        setPreviousValue(undefined);
        setIndex(undefined);
        setOpenPdl1ResultDialog(false);
    }, [previousValue, setFieldValue, index, setIndex, setPreviousValue, setOpenPdl1ResultDialog]);

    const onOk = React.useCallback(async() => {
        setFieldValue('submitType', 'save');
        await submit();

        const errors = getErrors();

        // dialog should remain open when there is any error in the current sample for central lab (form not saved)
        var criticalError = false;

        const siteErrors = ["F-PT-002", "F-PT-003", "F-PT-004"];

        Object.keys(errors).forEach(key => {
            if (!criticalError) {
                criticalError = errors[key].find(e => e.type >= Dtos.ValidationErrorType.Normal && !siteErrors.includes(e.code) && key.includes(`pdlAndTilResults[${index}]`)) != null
            }
        });

        if (!criticalError) {
            setOpenPdl1ResultDialog(false);
            setPreviousValue(undefined);
            setIndex(undefined);
        }
    }, [index, setIndex, setPreviousValue, setOpenPdl1ResultDialog, setFieldValue, submit, getErrors]);

    const onRowClick = React.useCallback((index: number) => {
        const v = getFieldValue(`pdlAndTilResults[${index}]`);

        setPreviousValue(!v ? undefined : cloneDeep(v));

        //setPreviousValue(undefined);
        setIndex(index);
        setOpenPdl1ResultDialog(true);
    }, [setIndex, setPreviousValue, setOpenPdl1ResultDialog]);

    // no manual adding of results
    //const onAddClick = React.useCallback((index: number) => {
    //    setPreviousValue(undefined);
    //    setIndex(index);
    //    setOpenPdl1ResultDialog(true);
    //}, [setIndex, setPreviousValue, setOpenPdl1ResultDialog]);

    const { values: formValues } = useFormState<Dtos.PdlAndTilResultForm, any>({ values: true });

    React.useEffect(() => {
        if (index != null) {
            const dateReceived = cloneDeep(formValues?.pdlAndTilResults[index]?.dateReceivedAtCentralLab);

            if (dateReceived) {
                const dateExpected = DateTime.fromISO(dateReceived).plus({ days: 7 })?.toISO({ includeOffset: false });

                setFieldValue(`pdlAndTilResults[${index}].dateExpected`, dateExpected);
            }
        }
    }, [formValues, index, setFieldValue]);

    const columnsToUse = React.useMemo(() => {
        return pdlAndTilResultsColumns;
    }, []);

    const { readOnly } = React.useContext(FormOptionsContext);

    return (
        <>
            {
                !!formValues?.modifiedDate && <Typography variant="body1" color="secondary" className={classes.instruction}>
                    Date Updated: {DateTime.fromISO(formValues?.modifiedDate).toFormat('dd-MMM-yyyy hh:mm a')}, User: {formValues?.modifiedBy}
                </Typography>
            }

            <FormGrid
                type={Dtos.PdlAndTilResult}
                name="pdlAndTilResults"
                columns={columnsToUse}
                onRowClick={onRowClick}
                allowAdd={false}
                allowDelete={() => false}
                noErrors={canAdministerPathologyTest ? false : true}
                hideRemoveColumn
            />

            {
                //JSON.stringify(formValues, null, '\t\n')
            }

            <Dialog
                open={openPdl1ResultDialog && index != null}
                onClose={onCancel}
                aria-labelledby="form-dialog"
                maxWidth="md"
                scroll="body"
                fullWidth
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle className={classes.dialogFormTitle}>PD-L1 and TIL Result</DialogTitle>
                <DialogContent style={{ padding: '0' }}>
                    <FieldProvider name={`pdlAndTilResults[${index}]`}>
                        <FieldGroup>
                            <Field
                                label="Not required"
                                name="notRequired"
                                component={Checkbox}
                            />
                        </FieldGroup>
                        <CrfCondition
                            type={Dtos.PdlAndTilResultForm}
                            condition={isNotRequiredNotChecked}
                            mode="Enable"
                            subscription={{ values: true }}
                        >
                            <FieldGroup>
                                <Field
                                    name="tumourBlockId"
                                    component={Text}
                                />
                            </FieldGroup>
                            <FieldGroup>
                                <Field
                                    name="centralLabId"
                                    component={Text}
                                    disabled={!canAdministerPathologyTest}
                                    noErrors={!canAdministerPathologyTest}
                                    noErrorFlag={!canAdministerPathologyTest}
                                />
                            </FieldGroup>
                            <FieldGroup>
                                <Field
                                    name="dateReceivedAtCentralLab"
                                    component={KeyboardDatePicker}
                                    disabled={!canAdministerPathologyTest}
                                    noErrors={!canAdministerPathologyTest}
                                    noErrorFlag={!canAdministerPathologyTest}
                                />
                            </FieldGroup>
                            <FieldGroup>
                                <Field
                                    name="dateExpected"
                                    component={KeyboardDatePicker}
                                    disabled
                                />
                                <Field
                                    name="specifyNewExpectedDate"
                                    component={Checkbox}
                                    label=""
                                    disabled={!canAdministerPathologyTest}
                                    noErrors={!canAdministerPathologyTest}
                                    noErrorFlag={!canAdministerPathologyTest}
                                />
                                <CrfCondition
                                    type={Dtos.PdlAndTilResultForm}
                                    condition={isNewExpectedDateSpecified}
                                    mode="Show"
                                    subscription={{ values: true }}
                                >
                                    <Field
                                        name="newExpectedDate"
                                        component={KeyboardDatePicker}
                                        disabled={!canAdministerPathologyTest}
                                        noErrors={!canAdministerPathologyTest}
                                        noErrorFlag={!canAdministerPathologyTest}
                                    />
                                    <Field
                                        name="newExpectedDateReason"
                                        component={Text}
                                        disabled={!canAdministerPathologyTest}
                                        noErrors={!canAdministerPathologyTest}
                                        noErrorFlag={!canAdministerPathologyTest}
                                    />
                                </CrfCondition>
                            </FieldGroup>
                            <FieldGroup>
                                <Field
                                    name="datePdlStainingCompleted"
                                    component={KeyboardDatePicker}
                                    disabled={!canAdministerPathologyTest}
                                    noErrors={!canAdministerPathologyTest}
                                    noErrorFlag={!canAdministerPathologyTest}
                                />
                            </FieldGroup>
                            <FieldGroup>
                                <Field
                                    name="dateTilStainingCompleted"
                                    component={KeyboardDatePicker}
                                    disabled={!canAdministerPathologyTest}
                                    noErrors={!canAdministerPathologyTest}
                                    noErrorFlag={!canAdministerPathologyTest}
                                />
                            </FieldGroup>
                            <FieldGroup>
                                <Field
                                    name="datePdlAssayAndTilCountReported"
                                    component={KeyboardDatePicker}
                                    disabled={!canAdministerPathologyTest}
                                    noErrors={!canAdministerPathologyTest}
                                    noErrorFlag={!canAdministerPathologyTest}
                                />
                            </FieldGroup>
                            <FieldGroup>
                                <Field
                                    name="pdlAssaySuccessful"
                                    component={ToggleButtonGroup}
                                    disabled={!canAdministerPathologyTest}
                                    noErrors={!canAdministerPathologyTest}
                                    noErrorFlag={!canAdministerPathologyTest}
                                />
                                <CrfCondition
                                    type={Dtos.PdlAndTilResultForm}
                                    condition={isPdlAssayNotSuccessful}
                                    mode="Enable"
                                    subscription={{ values: true }}
                                >
                                    <Field
                                        name="pdlAssayNotSuccessfulReason"
                                        component={Select}
                                        disabled={!canAdministerPathologyTest}
                                        noErrors={!canAdministerPathologyTest}
                                        noErrorFlag={!canAdministerPathologyTest}
                                    />
                                </CrfCondition>
                                <CrfCondition
                                    type={Dtos.PdlAndTilResultForm}
                                    condition={isPdlAssayNotSuccessfulReasonOther}
                                    mode="Enable"
                                    subscription={{ values: true }}
                                >
                                    <Field
                                        name="pdlAssayNotSuccessfulReasonSpecify"
                                        component={Text}
                                        disabled={!canAdministerPathologyTest}
                                        noErrors={!canAdministerPathologyTest}
                                        noErrorFlag={!canAdministerPathologyTest}
                                    />
                                </CrfCondition>
                            </FieldGroup>
                            <FieldGroup>
                                <CrfCondition
                                    type={Dtos.PdlAndTilResultForm}
                                    condition={isPdlAssaySuccessful}
                                    mode="Enable"
                                    subscription={{ values: true }}
                                >
                                    <Field
                                        name="pdlAssayResult"
                                        component={ToggleButtonGroup}
                                        disabled={!canAdministerPathologyTest}
                                        noErrors={!canAdministerPathologyTest}
                                        noErrorFlag={!canAdministerPathologyTest}
                                        md={6}
                                        lg={6}
                                        xl={6}
                                    />
                                </CrfCondition>
                                <CrfCondition
                                    type={Dtos.PdlAndTilResultForm}
                                    condition={isPdlAssayNotSuccessfulResubmission}
                                    mode="Enable"
                                    subscription={{ values: true }}
                                >
                                    <Field
                                        name="pdlAssayResultNoResult"
                                        component={Checkbox}
                                        label=""
                                        disabled={!canAdministerPathologyTest}
                                        noErrors={!canAdministerPathologyTest}
                                        noErrorFlag={!canAdministerPathologyTest}
                                    />
                                </CrfCondition>
                            </FieldGroup>
                            <FieldGroup>
                                <Field
                                    name="tilCountSuccessful"
                                    component={ToggleButtonGroup}
                                    disabled={!canAdministerPathologyTest}
                                    noErrors={!canAdministerPathologyTest}
                                    noErrorFlag={!canAdministerPathologyTest}
                                />
                                <CrfCondition
                                    type={Dtos.PdlAndTilResultForm}
                                    condition={isTilCountNotSuccessful}
                                    mode="Enable"
                                    subscription={{ values: true }}
                                >
                                    <Field
                                        name="tilCountNotSuccessfulReason"
                                        component={Select}
                                        disabled={!canAdministerPathologyTest}
                                        noErrors={!canAdministerPathologyTest}
                                        noErrorFlag={!canAdministerPathologyTest}
                                    />
                                </CrfCondition>
                                <CrfCondition
                                    type={Dtos.PdlAndTilResultForm}
                                    condition={isTilCountNotSuccessfulReasonOther}
                                    mode="Enable"
                                    subscription={{ values: true }}
                                >
                                    <Field
                                        name="tilCountNotSuccessfulReasonSpecify"
                                        component={Text}
                                        disabled={!canAdministerPathologyTest}
                                        noErrors={!canAdministerPathologyTest}
                                        noErrorFlag={!canAdministerPathologyTest}
                                    />
                                </CrfCondition>
                            </FieldGroup>
                            <FieldGroup>
                                <CrfCondition
                                    type={Dtos.PdlAndTilResultForm}
                                    condition={isTilCountSuccessful}
                                    mode="Enable"
                                    subscription={{ values: true }}
                                >
                                    <Field
                                        name="tilCountResult"
                                        component={Numeric}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    %&nbsp;
                                                </InputAdornment>
                                            )
                                        }}
                                        numberFormat={THREE_DIGIT_POSITIVE_INTEGER}
                                        disabled={!canAdministerPathologyTest}
                                        noErrors={!canAdministerPathologyTest}
                                        noErrorFlag={!canAdministerPathologyTest}
                                    />
                                </CrfCondition>
                                <CrfCondition
                                    type={Dtos.PdlAndTilResultForm}
                                    condition={isTilCountNotSuccessfulResubmission}
                                    mode="Enable"
                                    subscription={{ values: true }}
                                >
                                    <Field
                                        name="tilCountNoResult"
                                        component={Checkbox}
                                        label=""
                                        disabled={!canAdministerPathologyTest}
                                        noErrors={!canAdministerPathologyTest}
                                        noErrorFlag={!canAdministerPathologyTest}
                                    />
                                </CrfCondition>
                            </FieldGroup>
                            {
                                /* 
                                    this index check is needed as without it the removal of the source document field registration
                                    to the surrounding condition context ends up triggering too late. This results in all source documents
                                    on the form being deleted / changed to undefined (which in turn makes the server side error out).

                                    The index check removes the source documents earlier than the normal clean up would, which means
                                    they don't end up getting deleted.
                                */
                                (!!index || index === 0) && (
                                    <SourceDocuments
                                        instructions={
                                            <Typography className={classes.sdInstruction}>
                                                Please upload histopathology report for successful PD-L1 and TILs result.
                                            </Typography>
                                        }
                                    />
                                )
                            }
                        </CrfCondition>
                        
                        <div className={classes.dialogButton}>
                            <ProgressButton
                                variant="text"
                                type="button"
                                color="secondary"
                                onClick={onCancel}
                                loading={submitting}
                            >
                                { !readOnly ? 'Cancel' : 'Back' }
                            </ProgressButton>
                            {
                                !readOnly && (
                                    <ProgressButton
                                        variant="text"
                                        type="button"
                                        color="primary"
                                        onClick={onOk}
                                        loading={submitting}
                                    >
                                        Save
                                    </ProgressButton>
                                )
                            }
                        </div>
                    </FieldProvider>
                </DialogContent>
            </Dialog>
        </>
    );
}

const InsufficientTissueSection: React.FunctionComponent = () => {
    const classes = useStyles();

    const { institution } = React.useContext(InstitutionContext);
    const { patient, actions: patientActions } = React.useContext(PatientContext);

    const { getFieldValue, setFieldValue, submit } = useFormActions();
    const { form } = React.useContext(FormContext);

    const typedForm = form as Dtos.PdlAndTilResultForm;

    var pdlAndTilResults: Dtos.PdlAndTilResult[] = typedForm.pdlAndTilResults;

    var noMoreTissueAvailable: boolean = typedForm.noMoreTissueAvailable ?? false; 

    const successfulPdl1Assay = React.useMemo(() => {
        return pdlAndTilResults.filter(result => result.pdlAssaySuccessful === Dtos.LookupYesNoType.Yes as number) ?? [];
    }, [pdlAndTilResults]);

    const successfulTilsCount = React.useMemo(() => {
        return pdlAndTilResults.filter(result => result.tilCountSuccessful === Dtos.LookupYesNoType.Yes as number) ?? [];
    }, [pdlAndTilResults]);

    const promptInsufficientTissue = React.useMemo(() => {
      
        var incompleteSamples = pdlAndTilResults.filter(result => (result.pdlAssaySuccessful == null || result.tilCountSuccessful == null) && !result.notRequired) ?? [];

        if (incompleteSamples.length === 0) {

            if ((successfulPdl1Assay.length === 0 || successfulTilsCount.length === 0) && 
                noMoreTissueAvailable !== true &&
                (patient?.treatmentId == null || patient.treatmentId === Dtos.TreatmentType.Pdl1Pending) &&
                patient?.patientStateId !== Dtos.PatientStateType.Ineligible) {
                return true;
            }
        }

        return false;

    }, [patient, noMoreTissueAvailable, pdlAndTilResults, successfulPdl1Assay, successfulTilsCount]);

    const { setOpenTumourSampleDialog } = React.useContext(TableContext);

    const [openInsufficientTissueDialog, setOpenInsufficientTissueDialog] = React.useState(false);

    const onYesClick = React.useCallback(() => {
        setOpenTumourSampleDialog(true);
    }, [setOpenTumourSampleDialog]);

    const { enqueueSnackbar } = useSnackbar();

    const history = useHistory();

    const { actions: validationActions } = React.useContext(PatientValidationContext);

    const updatePatientState = React.useCallback(async (patientStateId: Dtos.PatientStateType, skipPatientEmail?: boolean) => {
        return await patientActions.asyncSave(new Dtos.Patient({ ...patient, previousPatientStateId: patient?.patientStateId, patientStateId: patientStateId, skipEmailingPatient: skipPatientEmail }))
            .then(() => {
                validationActions.load();
            })
            .catch(() => {

            });

    }, [patientActions, validationActions, patient]);

    const onIneligibleClick = React.useCallback(async () => {
        try {
            await updatePatientState(Dtos.PatientStateType.Ineligible);

            setFieldValue('noMoreTissueAvailable', true);
            setFieldValue('submitType', 'save');

            submit();

            history.push(`/registration/${institution?.code}/${patient?.studyNumber}`);

            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Participant Marked As Ineligible
                    </AlertTitle>
                    The participant was successfully marked ineligible.
                </>,
                { variant: 'success' }
            );
        }
        catch (error) {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Participant Not Marked As Ineligible
                    </AlertTitle>
                    An error occurred while attempting to mark the participant as ineligible.
                </>,
                { variant: 'critical' }
            );
        }
    }, [updatePatientState, enqueueSnackbar, institution, patient, history, setFieldValue, submit]);

    const onContinueClick = React.useCallback(async () => {
        setFieldValue('noMoreTissueAvailable', true);
        setFieldValue('submitType', 'save');

        submit();

        setOpenInsufficientTissueDialog(false);
    }, [setFieldValue, submit, setOpenInsufficientTissueDialog]);

    return (
        <>
            {
                !!promptInsufficientTissue && (
                    <Box
                        style={{ padding: '16px 0px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                    >
                        <Typography>
                            There is insufficient tissue to complete the PD-L1 and/or TILs testing. Is there more tissue available that can be submitted to continue this testing?
                        </Typography>
                        <Box>
                            <Button
                                variant="contained"
                                type="button"
                                color="primary"
                                style={{ margin: '16px 8px' }}
                                onClick={onYesClick}
                                size="small"
                            >
                                Yes
                            </Button>
                            <Button
                                variant="contained"
                                type="button"
                                color="primary"
                                onClick={() => setOpenInsufficientTissueDialog(true)}
                                style={{ margin: '16px 8px' }}
                                size="small"
                            >
                                No
                            </Button>
                        </Box>
                    </Box>
                )
            }

            <Dialog
                open={openInsufficientTissueDialog}
                onClose={() => setOpenInsufficientTissueDialog(false)}
                aria-labelledby="form-dialog"
                maxWidth="sm"
                scroll="body"
                fullWidth
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle className={classes.dialogTitle}>No more tissue sample available for participant {patient?.studyNumber}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                    {
                        successfulPdl1Assay.length === 0 ? 
                            "There's no successful PD-L1 assay for this participant. The participant will be in PD-L1 Negative/Unknown Cohort."
                            :
                            "There's no successful TILs count for this participant."
                    }
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button
                        variant="text"
                        type="button"
                        color="secondary"

                        onClick={() => setOpenInsufficientTissueDialog(false)}
                    >
                        Cancel
                    </Button>
                    {
                        //successfulPdl1Assay.length === 0 ?
                        //    <Button
                        //        variant="text"
                        //        type="button"
                        //        color="primary"
                        //        onClick={onIneligibleClick}
                        //    >
                        //        Mark as Ineligible
                        //    </Button>
                        //    :
                        <Button
                            variant="text"
                            type="button"
                            color="primary"
                            onClick={onContinueClick}
                        >
                            Continue
                        </Button>
                    }
                </DialogActions>
            </Dialog>
        </>
    );
}

const TumourSampleDialog: React.FunctionComponent = () => {
    const { index, setIndex } = React.useContext(TableContext);

    const { getFieldValue, setFieldValue } = useFormActions();

    var tumourBlocks: Dtos.TumourBlock[] = getFieldValue('tumourBlocks') ?? [];

    const { patient } = React.useContext(PatientContext);

    // create a new tumour block / sample
    React.useEffect(() => {
        setFieldValue('tumourBlocks', [...tumourBlocks, new Dtos.TumourBlock({ patientStateId: patient?.patientStateId, sampleNumber: tumourBlocks.length+1 })]);
        setIndex([...tumourBlocks, new Dtos.TumourBlock({})].length - 1);
    }, [patient]);

    return (
        <>
            <FieldProvider name={`tumourBlocks[${index}]`}>
                <Field
                    name="sampleType"
                    component={Select}
                    md={7}
                    lg={7}
                    xl={7}
                />
                <Field
                    name="sampleSite"
                    component={Select}
                    md={7}
                    lg={7}
                    xl={7}
                />
                <CrfCondition
                    type={Dtos.TumourBlock}
                    condition={isSampleSiteOther}
                    mode="Enable"
                    subscription={{ values: true }}
                >
                    <Field
                        name="sampleSiteSpecify"
                        component={Text}
                        md={7}
                        lg={7}
                        xl={7}
                    />
                </CrfCondition>
                <Field
                    name="dateCollected"
                    component={KeyboardDatePicker}
                    md={7}
                    lg={7}
                    xl={7}
                />
                <Field
                    name="ffpeBlockOrSlides"
                    component={Select}
                    md={7}
                    lg={7}
                    xl={7}
                />
                <CrfCondition
                    type={Dtos.TumourBlock}
                    condition={isSampleSlides}
                    mode="Enable"
                    subscription={{ values: true }}
                >
                    <Field
                        name="dateSlidesWereCut"
                        component={KeyboardDatePicker}
                        md={7}
                        lg={7}
                        xl={7}
                    />
                </CrfCondition>
                <Field
                    name="tumourBlockId"
                    component={Text}
                    md={7}
                    lg={7}
                    xl={7}
                />
                <CrfCondition
                    type={Dtos.TumourBlock}
                    condition={isSampleSlides}
                    mode="Enable"
                    subscription={{ values: true }}
                >
                    <Field
                        name="slideIdStart"
                        component={Numeric}
                        numberFormat={{ allowNegative: false, decimalScale: 0 }}
                        md={7}
                        lg={7}
                        xl={7}
                    />
                    <Field
                        name="slideIdEnd"
                        component={Numeric}
                        numberFormat={{ allowNegative: false, decimalScale: 0 }}
                        md={7}
                        lg={7}
                        xl={7}
                    />
                </CrfCondition>
            </FieldProvider>
        </>
    );
}

const PdlAndTilResult: React.FunctionComponent<IPdlAndTilResultProps> = () => {
    const classes = useStyles();

    const history = useHistory();

    const { enqueueSnackbar } = useSnackbar();

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient, actions: patientActions } = React.useContext(PatientContext);

    const [[canUpdateCrf, canAdministerOpms, canAdministerPathologyTest], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, true, false);

    const [openPdl1ResultDialog, setOpenPdl1ResultDialog] = React.useState<boolean>(false);

    const [openTumourSampleDialog, setOpenTumourSampleDialog] = React.useState<boolean>(false);

    const [index, setIndex] = React.useState<number | undefined>(undefined);

    const tableContext: ITableContext = React.useMemo(() => {
        return {
            index,
            setIndex: (i) => setIndex(i),
            openPdl1ResultDialog: openPdl1ResultDialog,
            setOpenPdl1ResultDialog: (open) => setOpenPdl1ResultDialog(open),
            openTumourSampleDialog: openTumourSampleDialog,
            setOpenTumourSampleDialog: (open) => setOpenTumourSampleDialog(open),
        }

    }, [index, setIndex, openPdl1ResultDialog, setOpenPdl1ResultDialog, openTumourSampleDialog, setOpenTumourSampleDialog]);

    const [, , , pdlAndTilFormActions] = useFormByCodes("pdl-and-til-result-form", institution?.code, patient?.studyNumber, "pdl-and-til-result", 1, 1, false, false, false, false);

    const afterSampleSave = React.useCallback(() => {
        setOpenTumourSampleDialog(false);
        setIndex(undefined);
        pdlAndTilFormActions.load();
    }, [setOpenTumourSampleDialog, setIndex, pdlAndTilFormActions]);

    const onSampleCancel = React.useCallback(() => {
        setOpenTumourSampleDialog(false);
        setIndex(undefined);
    }, [setOpenTumourSampleDialog, setIndex]);

    const onFormCancel = React.useCallback(() => {
        history.push(`/registration/${institution?.code}/${patient?.studyNumber}`)
    }, [history, institution, patient]);

    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);

    const client = onlinePatientManagement.serviceStackClient;

    const [requisitionFormDownloading, setRequisitionFormDownloading] = React.useState(false);

    const onGenerateRequisitionFormClick = React.useCallback(() => {
        setRequisitionFormDownloading(true);

        client.get(new Dtos.GetRequisitionForm({
                patientId: patient?.id
            }))
            .then(response => {
                setRequisitionFormDownloading(false);
            })
            .catch((e) => {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Error
                        </AlertTitle>
                        An error occurred while trying to download the requisition form. Please contact your system administrator.
                    </>,
                    { variant: 'critical' }
                );
                setRequisitionFormDownloading(false)
            });
    }, [client, patient, setRequisitionFormDownloading]);

    // submit is blocked when there is any error.
    const allowSubmit = React.useCallback(async ({ errors, values }: IFormState<Dtos.PdlAndTilResultForm, IValidationError>, formActions: IFormActions<Dtos.PdlAndTilResultForm, IValidationError>) => {
        if (!errors) {
            return true;
        }

        const siteErrors = ["F-PT-002", "F-PT-003", "F-PT-004", 'F-PTR-030'];

        Object.keys(errors).forEach(key => {
            errors[key] = (errors[key]).filter(error => !siteErrors.includes(error.code) && key.includes(`[${index}]`));
        });

        return !Object.keys(errors)
            .some(key => errors[key] && errors[key]
                .some(e => (e?.type ?? Dtos.ValidationErrorType.Warning) >= Dtos.ValidationErrorType.Normal));
    }, [index]);

    const onFormSubmitValidationFailure = React.useCallback(async ({ errors, values }: IFormState<Dtos.PdlAndTilResultForm, IValidationError>, validationError: boolean) => {
        if (validationError) {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Error
                    </AlertTitle>
                    An error occurred while attempting to validate the form.
                </>,
                { variant: 'critical' }
            );
        }
        else {
            const criticalErrors = Object
                .keys(errors)
                .reduce((array: IValidationError[], key: string) => {
                    const propertyErrors = errors[key]?.reduce((propertyArray: IValidationError[], e: IValidationError) => {
                        if (e?.type ?? Dtos.ValidationErrorType.Warning >= Dtos.ValidationErrorType.Normal) {
                            return [...propertyArray, e]
                        }

                        return propertyArray;
                    }, [])

                    return [...array, ...propertyErrors]
                }, []);

            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Form Not Saved
                    </AlertTitle>
                    Please correct the blocking {pluralize('error', criticalErrors.length)} and try again.
                </>,
                { variant: 'critical' }
            );
        }
    }, [enqueueSnackbar]);

    if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }

    return (
        <>
            <FormBreadcrumbs />
            <TableContext.Provider value={tableContext}>
                {
                    openPdl1ResultDialog != null && <CrfForm
                        formType={Dtos.PdlAndTilResultForm}
                        validateOn="onChange"
                        canEdit={canAdministerPathologyTest && (!patient?.treatmentId || patient?.treatmentId === 3)}
                        allowSubmit={allowSubmit}
                        onFormSubmitValidationFailure={onFormSubmitValidationFailure}
                        hideButtons
                        afterFormSave={() => patientActions.load()}
                    >
                        <PdlAndTilResultsTbl />

                        {
                            // site or BCT
                            (!!canAdministerOpms || (canUpdateCrf && !canAdministerOpms && !canAdministerPathologyTest)) && <InsufficientTissueSection />
                        }

                        <div
                            className={classes.buttonGroup}
                        >
                            {
                                (!!canAdministerOpms || (canUpdateCrf && !canAdministerOpms && !canAdministerPathologyTest)) && <Button
                                    variant="contained"
                                    color="primary"
                                    //loading={requisitionFormDownloading}
                                    href={`/get/requisition-form/${patient?.id}/`}
                                    component="a"
                                    //onClick={onGenerateRequisitionFormClick}
                                >
                                    Generate Requisition Form
                                </Button>
                            }

                            <Button
                                variant="contained"
                                color="primary"
                                onClick={onFormCancel}
                            >
                                Back
                            </Button>
                        </div>
                    </CrfForm>
                }

                <Dialog
                    open={openTumourSampleDialog}
                    onClose={() => setOpenTumourSampleDialog(false)}
                    aria-labelledby="form-dialog"
                    maxWidth="sm"
                    scroll="body"
                    fullWidth
                    disableBackdropClick
                    disableEscapeKeyDown
                >
                    <DialogContent style={{ padding: 0 }}>
                        <DialogFormResolver
                            institutionCode={institution?.code}
                            patientStudyNumber={patient?.studyNumber}
                            eventDefinitionCode={"pre-registration"}
                            eventRepeat={1}
                            formDefinitionCode={"tumour-sample-submission"}
                            formRepeat={1}
                            resolveBeforeLoad
                        >
                            <CrfDialogForm
                                formType={Dtos.TumourBlockSubmission}
                                validateOn="onChange"
                                canEdit={canUpdateCrf}
                                ignoreReadOnly={true}
                                onCancel={() => onSampleCancel()}
                                afterFormSave={() => afterSampleSave()}
                                fieldErrors="default"
                            >
                                <TumourSampleDialog />
                            </CrfDialogForm>
                        </DialogFormResolver>
                    </DialogContent>
                </Dialog>
            </TableContext.Provider>
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default PdlAndTilResult;
