/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the sae landing page component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { Button, Grid, Theme, Typography, makeStyles } from '@material-ui/core';

import { useHistory, useParams } from 'react-router-dom';

import {
    ALL_INSTITUTIONS_CODE,
    IFormLabel,
    InstitutionBreadcrumbs,
    InstitutionsContext,
    PatientsByCodesResolver,
    PatientsContext,
    RouteLoading,
    TrialContextSelector
} from '@ngt/opms';

import {
    ScreeningLogResolver,
    ScreeningLogsCollapsibleTable,
    useScreeningLogs
} from '@ngt/opms-screeninglog';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import { usePermissionsByIds } from '@ngt/opms-bctapi';
import { ScreeningLogDialog, ScreeningLogDialogMode } from '@ngt/opms-screeninglog';
import { RequestState } from '@ngt/request-utilities';
import { DateTime } from 'luxon';
import * as Dtos from '../../api/dtos';
import useScreenFailsColumns from '../../hooks/screeninglogs/useScreenFailsColumns';
import useScreenedParticipantsColumns from '../../hooks/screeninglogs/useScreenedParticipantsColumns';
import ScreeningLogFieldGroup from './ScreeningLogFieldGroup';
import { Alert } from '@material-ui/lab';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IScreeningLogPageParams {
    institutionCode?: string;
}

interface IScreeningLogPageProps {
}

interface IScreeningLogsProps {
    institution?: Dtos.Institution;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    createGrid: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-Start',
        alignItems: 'flex-end'
    },
    createGridBtn: {
        '&:first-child': {
            marginRight: theme.spacing(1)
        },
        width: '100%'
    },
    ml1: {
        marginLeft: theme.spacing(1),
    },
    mt1: {
        marginTop: theme.spacing(1),
    },
    mt3: {
        marginTop: theme.spacing(3)
    },
    mr1: {
        marginRight: theme.spacing(1)
    },
    recruitmentDisabled: {
        marginRight: theme.spacing(1),
        paddingTop: 0,
        paddingBottom: 0
    },
}));

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

const permissions: Dtos.Permission[] = [
    Dtos.Permission.ScreeningLogView,
    Dtos.Permission.ScreeningLogUpdate
];

const patientStates = [Dtos.PatientStateType.NewPatient, Dtos.PatientStateType.Preregistered, Dtos.PatientStateType.Registered, Dtos.PatientStateType.Ineligible];

const ScreeningLogTables: React.FunctionComponent<{ setScreeningLogId: any, setDialogMode: any }> = ({
    setScreeningLogId,
    setDialogMode
}) => {

    const { institutions } = React.useContext(InstitutionsContext);

    const { institutionCode } = useParams<Record<string, string>>();

    const showInstitutions = React.useMemo(() => {
        return !institutionCode
    }, [institutionCode]);

    const institution = React.useMemo(() => {
        return institutions?.find(i => i.code === institutionCode);
    }, [institutions, institutionCode]);

    const [screeningLogs, screeningLogsLoadState, ] = useScreeningLogs(institution?.id, true);

    const pendingScreeningColumns = useScreenedParticipantsColumns(institutions, showInstitutions, false);

    const successfulScreeningColumns = useScreenedParticipantsColumns(institutions, showInstitutions, true);

    const screenFailsColumns = useScreenFailsColumns(institutions, showInstitutions);

    const handleUpdate = (event: React.MouseEvent<Element, MouseEvent>, rowData?: Dtos.IScreeningLog | undefined) => {
        if (!rowData?.id) {
            throw new Error('Screening log ID was not provided.');
        }
        setScreeningLogId(rowData?.id);
        setDialogMode(ScreeningLogDialogMode.update);
    };

    const handleDelete = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id?: number) => {
        if (!id) {
            throw new Error('Screening log ID was not provided.');
        }

        event.stopPropagation();

        setScreeningLogId(id);
        setDialogMode(ScreeningLogDialogMode.delete);
    };

    const pendingScreeningLogsToUse: Dtos.IScreeningLog[] = React.useMemo(() => {
        return screeningLogs?.filter(s => s.status === Dtos.ScreeningStatus.Pending) ?? [];
    }, [screeningLogs]);

    const econsentScreeningLogsToUse: Dtos.IScreeningLog[] = React.useMemo(() => {

        return screeningLogs?.filter(s => {

            if ((s as Dtos.ScreeningLog).econsentInvitationId != null && s.patientId == null) {
                return true;
            }

            return false;
        }) ?? [];

    }, [screeningLogs]);

    const { patients } = React.useContext(PatientsContext);

    const preregisteredScreeningLogsToUse: Dtos.IScreeningLog[] = React.useMemo(() => {

        return screeningLogs?.filter(s => {

            if (s.patientId != null) {
                const patient = patients?.find(p => p.id === s.patientId);

                return patient?.patientStateId === Dtos.PatientStateType.NewPatient || patient?.patientStateId === Dtos.PatientStateType.Preregistered;
            }

            return false;
        }) ?? [];

    }, [screeningLogs, patients]);

    const registeredScreeningLogsToUse: Dtos.IScreeningLog[] = React.useMemo(() => {

        return screeningLogs?.filter(s => {

            if (s.patientId != null) {
                const patient = patients?.find(p => p.id === s.patientId);

                return patient?.patientStateId === Dtos.PatientStateType.Registered;
            }

            return false;
        }) ?? [];

    }, [screeningLogs, patients]);

    const failedScreeningLogsToUse: Dtos.IScreeningLog[] = React.useMemo(() => {
        return screeningLogs?.filter(s => s.status === Dtos.ScreeningStatus.Failed) ?? [];
    }, [screeningLogs]);

    return (
        <>
            <ScreeningLogsCollapsibleTable
                columns={pendingScreeningColumns}
                title={"Participants in Screening"}
                pluralizeTitle={false}
                data={pendingScreeningLogsToUse}
                loading={screeningLogsLoadState.state === RequestState.None || screeningLogsLoadState.state === RequestState.Pending}
                onRowClick={handleUpdate as any}
                onRemove={handleDelete as any}
                allowDelete={true}
            />

            <ScreeningLogsCollapsibleTable
                columns={pendingScreeningColumns}
                title={"Participants in eConsent"}
                pluralizeTitle={false}
                data={econsentScreeningLogsToUse}
                loading={screeningLogsLoadState.state === RequestState.None || screeningLogsLoadState.state === RequestState.Pending}
                onRowClick={handleUpdate as any}
                onRemove={handleDelete as any}
                allowDelete={false}
            />

            <ScreeningLogsCollapsibleTable
                columns={successfulScreeningColumns}
                title={"Participants in Registration"}
                pluralizeTitle={false}
                data={preregisteredScreeningLogsToUse}
                loading={screeningLogsLoadState.state === RequestState.None || screeningLogsLoadState.state === RequestState.Pending}
                onRowClick={handleUpdate as any}
                onRemove={handleDelete as any}
                allowDelete={false}
            />

            <ScreeningLogsCollapsibleTable
                columns={successfulScreeningColumns}
                title={"Participants Registered"}
                pluralizeTitle={false}
                data={registeredScreeningLogsToUse}
                loading={screeningLogsLoadState.state === RequestState.None || screeningLogsLoadState.state === RequestState.Pending}
                onRowClick={handleUpdate as any}
                onRemove={handleDelete as any}
                allowDelete={false}
            />

            <ScreeningLogsCollapsibleTable
                columns={screenFailsColumns}
                title={"Failed Screenings/Registration"}
                pluralizeTitle={false}
                data={failedScreeningLogsToUse}
                loading={screeningLogsLoadState.state === RequestState.None || screeningLogsLoadState.state === RequestState.Pending}
                onRowClick={handleUpdate as any}
                onRemove={handleDelete as any}
            />
        </>
    );
}

const ScreeningLogs: React.FunctionComponent<IScreeningLogsProps> = ({
    institution
}) =>
{
    const classes = useStyles();

    const history = useHistory();

    const [[canViewScreeningLog, canUpdateScreeningLog], permissionLoadState, ] = usePermissionsByIds(permissions, null, null, institution?.id, null, !!institution?.id);

    const onSelect = React.useCallback((newMasterGroupCode?: string | null, newCollaboratingGroupCode?: string | null, newCountryCode?: string | null, newInstitutionCode?: string | null) => {
        if (newInstitutionCode) {
            history?.push(`/screening/${newInstitutionCode}`);
        }
        else {
            history?.push(`/screening`);
        }

        return;
    }, [history]);

    const [dialogMode, setDialogMode] = React.useState(0);

    const [screeningLogId, setScreeningLogId] = React.useState<number | undefined>(undefined);

    const handleAdd = () => {
        setDialogMode(ScreeningLogDialogMode.create);
    };

    const handleCancel = () => {
        setScreeningLogId(undefined);
        setDialogMode(ScreeningLogDialogMode.cancel)
    }; 

    const labels: IFormLabel[] = [
        {
            name: 'screeningDate',
            label: 'Screening date',
            detailedLabel: ''
        },
        {
            name: 'initials',
            label: 'Initials',
            detailedLabel: ''
        },
        {
            name: 'dateOfBirth',
            label: 'Date of birth',
            detailedLabel: ''
        },
        {
            name: 'gender',
            label: 'Gender',
            detailedLabel: ''
        },
        {
            name: 'outcome',
            label: 'Outcome',
            detailedLabel: ''
        },
        {
            name: 'reason',
            label: 'Reason',
            detailedLabel: ''
        },
        {
            name: 'reasonSpecify',
            label: 'Details of failed eligibility criteria and/or any other additional relevant comments',
            detailedLabel: ''
        },
        {
            name: 'patientUsingEconsent',
            label: 'Will this patient be consented using the online consent system?',
            detailedLabel: ''
        },
        {
            name: 'econsentEmail',
            label: 'Email address',
            detailedLabel: ''
        },
        {
            name: 'econsentInvestigator',
            label: 'Investigator',
            detailedLabel: ''
        }
    ];

    const downloadButtons =
        <>
            <Button
                variant="contained"
                color="primary"
                size="medium"
                component="a"
                className={classes.mr1}
                href={`/print/screeninglogs/${(institution?.code ?? 'all-institutions')}/pdf`}
            >
                PDF
            </Button>
            <Button
                variant="contained"
                color="primary"
                size="medium"
                component="a"
                href={`/print/screeninglogs/${(institution?.code ?? 'all-institutions')}/csv`}
            >
                CSV
            </Button>
        </>;

    // const { patients, actions: patientsActions } = React.useContext(PatientsContext);

    // const getPatientStudyNumber = React.useCallback((patientId: number) => {
    //     patientsActions.load();

    //     return patients?.find(p => p.id == patientId)?.studyNumber
    // }, [patients, patientsActions]);

    return (
        <>
            <InstitutionBreadcrumbs />
            <div
                className={classes.container}
            >
                <Typography
                    className={classes.mt1}
                >
                    Any patients identified as potentially eligible for trial participation based on an initial review of their breast cancer history and prior treatment should be added to the screening log
                    (i.e. patients with pre-treated advanced HER2-positive breast cancer). This includes those who did not proceed to pre-registration or declined trial participation. 
                    Click ‘Add Screening Log’ to add a screened patient to the log and enter the available information. Screening logs with incomplete responses can be saved and complete later once the missing information becomes available. Once all the questions are answered correctly, complete the screening to move the patient to the next stage. Depending on the answers provided on the form, the patient may go further to 'eConsent' or 'Registration', or fall under 'Failed Screenings'.
                    To view or update a patient's screening log, simply click the record in the table. 
                    <br /><br />
                    NOTE: All patients must complete the screening for their record to be available in the Registration system. Their basic details will be automatically completed in the Registration system once all the prerequisites are done.
                </Typography>

                <Grid item xs={12} className={classes.mt3}>
                    <TrialContextSelector
                        onChange={onSelect}
                        allowAllMasterGroups={false}
                        allowAllCollaboratingGroups={false}
                        allowAllCountries={false}
                        allowAllInstitutions={true}
                        hideMasterGroups={true}
                        hideCollaboratingGroups={true}
                        hideCountries={true}
                        masterGroupCode={null}
                        collaboratingGroupCode={null}
                        countryCode={null}
                        institutionCode={institution?.code ?? ALL_INSTITUTIONS_CODE}
                    >
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            className={classes.createGrid}
                        >
                            {
                                canUpdateScreeningLog &&
                                (institution?.recruitmentDisabled !== true) &&
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="medium"
                                    onClick={handleAdd}
                                    className={classes.createGridBtn}
                                    disabled={!institution?.code}
                                >
                                    Add Screening Log
                                </Button>
                            }
                            {
                                institution &&
                                    institution.recruitmentDisabled ?
                                    <Alert
                                        className={classes.recruitmentDisabled}
                                        variant="filled"
                                        severity="info"
                                    >
                                        Site Disabled
                                    </Alert> :
                                    null
                            }
                            {
                                canViewScreeningLog &&
                                downloadButtons
                            }
                        </Grid>
                    </TrialContextSelector>
                </Grid>

                {
                    permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending ?
                        <RouteLoading />
                        :
                        <ScreeningLogTables
                            setScreeningLogId={setScreeningLogId}
                            setDialogMode={setDialogMode}
                        />
                }

                {
                    (!!institution?.id || !!screeningLogId) && (
                        <ScreeningLogResolver id={screeningLogId} institutionId={institution?.id}>
                            <ScreeningLogDialog
                                mode={dialogMode}
                                formCancel={handleCancel}
                                formFieldGroup={<ScreeningLogFieldGroup />}
                                formLabels={labels}
                                formCreateSuccessfulSaveMessage={(formAndSubmit) => {
                                    const submitType = formAndSubmit.submitType;
                                    const screeningLog = formAndSubmit.form as Dtos.ScreeningLog;

                                    if (submitType === "save") {
                                        return `Participant ${screeningLog.initials} (DOB: ${screeningLog.dateOfBirth && DateTime.fromISO(screeningLog.dateOfBirth).toFormat('dd/MM/yyyy')}) was successfully saved to "Participants in Screening".`
                                    } else if (submitType === "submit") {

                                        if (screeningLog.outcome === Dtos.ScreeningOutcome.ProceedToRegistration) {
                                            if (screeningLog.patientUsingEconsent !== Dtos.LookupYesNoType.Yes as number) {
                                                return `Participant ${screeningLog.patientStudyNumber} (Initials: ${screeningLog.initials}, DOB: ${screeningLog.dateOfBirth && DateTime.fromISO(screeningLog.dateOfBirth).toFormat('dd/MM/yyyy')}) was successfully added to Registration. Click on Registration tab to complete this participant’s pre-registration.`
                                            } else {
                                                return `Participant ${screeningLog.initials} (DOB: ${screeningLog.dateOfBirth && DateTime.fromISO(screeningLog.dateOfBirth).toFormat('dd/MM/yyyy')}) was successfully saved to "Participants in eConsent". Click on eConsent tab to complete this participant’s consent.`
                                            }
                                        } else if (screeningLog.outcome === Dtos.ScreeningOutcome.ScreenFail) {
                                            return `Participant ${screeningLog.initials} (DOB: ${screeningLog.dateOfBirth && DateTime.fromISO(screeningLog.dateOfBirth).toFormat('dd/MM/yyyy')}) was successfully saved as "Screen Fail".`
                                        }
                                    }

                                    return 'Screening log was successfully saved.'
                                }}
                            />
                        </ScreeningLogResolver>
                    )
                }
            </div>
        </>
    )
}

const ScreeningLogPage: React.FunctionComponent<IScreeningLogPageProps> = () =>
{
    const { institutionCode } = useParams<Record<string, string>>();

    const contextInstitutions = React.useContext(InstitutionsContext);

    const institutions = contextInstitutions?.institutions;

    const institution = React.useMemo(() => {
        return institutions?.find(i => i.code === institutionCode);
    }, [institutions, institutionCode]);

    return (
        <>
            <PatientsByCodesResolver
                masterGroupCode={null}
                collaboratingGroupCode={null}
                countryCode={null}
                institutionCode={institution?.code}
                patientStateIds={patientStates}
            >
                <ScreeningLogs
                    institution={institution}
                />
            </PatientsByCodesResolver>
        </>
    )
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */
export default ScreeningLogPage;