/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';

/*
 * Used to get access to router context.
 */
import { useHistory, useParams } from 'react-router-dom';

/*
 * Used to style components
 */
import { Grid, makeStyles, TextField, Button } from '@material-ui/core';

/**
 * Used for the basic page layout.
 */
import {
    ALL_INSTITUTIONS_CODE,
    CollapsibleTable,
    InstitutionBreadcrumbs,
    InstitutionsContext,
    OnlinePatientManagementContext,
    PatientsByCodesResolver,
    PatientsContext,
    TrialContextSelector,

    useSnackbar,
    usePatientsByCodes
} from '@ngt/opms';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import { Permission, usePermissionsByIds } from '@ngt/opms-bctapi';
import { RequestState } from '@ngt/request-utilities';
import { Column } from 'material-table';
import * as Dtos from '../api/dtos';
import useIneligiblePatientColumns from '../hooks/useIneligiblePatientColumns';
import useNewPatientColumns from '../hooks/useNewPatientColumns';
import usePreregisteredPatientColumns from '../hooks/usePreregisteredPatientColumns';
import useRegisteredPatientColumns from '../hooks/useRegisteredPatientColumns';
import { AlertTitle } from '@material-ui/lab';

/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IRegistrationParams {
    masterGroupCode?: string
    collaboratingGroupCode?: string
    countryCode?: string
    institutionCode?: string
}

interface IRegistrationProps {

}

interface IPatientTableProps {
    title: string;
    entityName: string;
    columns: Array<Column<Dtos.Patient>>;
    onRowClick?: (event: any, rowData: any) => void,
    centralLab?: boolean;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    addPatientColumn: {
        display: 'flex',
        alignItems: 'flex-end'
    },
    addPatient: {
        width: '100%'
    },
    search: {
        display: 'flex',
        alignItems: 'flex-end',
        paddingRight: '12px',
        paddingTop: '12px',
        paddingBottom: '12px',
        gap: 24
    },
}));

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Permission[] = [
    Permission.OpmsDataManage,
    Permission.OpmsPatientRegister,
    Permission.PathologyTestAdminister
];

const newStates = [Dtos.PatientStateType.NewPatient];
const preregisteredStates = [Dtos.PatientStateType.Preregistered];
const registeredStates = [Dtos.PatientStateType.Registered];
const ineligibleStates = [Dtos.PatientStateType.Ineligible];

const tableOptions = {
    pageSize: 20,
    pageSizeOptions: [20]
};

const MemoizedCollapsibleTable = React.memo(CollapsibleTable);

const PatientTable: React.FunctionComponent<IPatientTableProps> = ({
    title,
    entityName,
    columns,
    onRowClick,
    centralLab
}) => {
    const [tableCount, setTableCount] = React.useState(1);

    const { patients: contextPatients, loadState: patientsLoadState } = React.useContext(PatientsContext);

    const [patientsToUse, setPatientsToUse] = React.useState<Dtos.Patient[]>([]);

    const patientsLoadingToUse = patientsLoadState.state === RequestState.None || patientsLoadState.state === RequestState.Pending;

    React.useEffect(() => {
        setPatientsToUse((contextPatients as Dtos.Patient[])?.filter(p => !centralLab || p.preregistrationDate != null))
    }, [centralLab, contextPatients, setPatientsToUse]);

    React.useEffect(() => {
        setTableCount(x => x + 1);
    }, [patientsToUse, columns]);

    return (
        <MemoizedCollapsibleTable
            key={tableCount}
            data={patientsToUse ?? []}
            title={title ?? "Participants"}
            loading={patientsLoadingToUse}
            entityName={entityName ?? "Participant"}
            columns={columns as any}
            onRowClick={onRowClick as any}
            options={tableOptions}
        />
    );
}

const Search: React.FunctionComponent<{}> = ({}) => {

    const history = useHistory();


    const [patients] = usePatientsByCodes(null, null, null, null, null, true);

    const [searchValue, setSearchValue] = React.useState(null);

    const { enqueueSnackbar } = useSnackbar();

    const saveText = React.useCallback((e) => {
        setSearchValue(e.target.value);
    }, [searchValue, setSearchValue]);

    const search = React.useCallback(() => {
        if (patients?.some(p => p.studyNumber === searchValue)) {
            history.push(`/registration/all-institutions/` + searchValue);
        }
        else {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Patient Not Found
                    </AlertTitle>
                    {searchValue ? <>Patient '{searchValue}' not found. Please ensure you have correctly input the patients study number.</> : <>The search field is empty.</>}
                </>,
                { variant: 'error' }
            );
        }
    }, [searchValue]);

    return (
        <>
            
            <TextField
                variant="standard"
                label="Patient Study Number"
                id='patientNumberSearch'
                onChange={saveText}
                style={{
                    flex: '1 1 auto',
                    width: '60%'
                }}
            />
            <Button
                variant='contained'
                onClick={search}

                style={{
                    flex: '1 1 auto',
                    width: '40%'
                }}
            >
                Search
            </Button>
        </>
    )
}

const Registration: React.FunctionComponent<IRegistrationProps> = () => {
    const classes = useStyles();

    const history = useHistory();

    const { masterGroupCode, collaboratingGroupCode, countryCode, institutionCode } = useParams<IRegistrationParams>();

    const onSelect = React.useCallback((newMasterGroupCode?: string | null, newCollaboratingGroupCode?: string | null, newCountryCode?: string | null, newInstitutionCode?: string | null) => {
        if (newInstitutionCode) {
            history.push(`/registration/${newInstitutionCode}`);
        }
        else {
            history.push(`/registration`);
        }

        return;
    }, [history]);

    const parsedInstitutionCode = React.useMemo(() => {
        return institutionCode !== ALL_INSTITUTIONS_CODE ? institutionCode : null;
    }, [institutionCode]);

    const { institutions } = React.useContext(InstitutionsContext);

    const institution = React.useMemo(() => {
        return institutions?.find(institution => institution.code === parsedInstitutionCode);
    }, [parsedInstitutionCode, institutions]);

    const [[canAdministerOpms, , canAdministerPathologyTest], ] = usePermissionsByIds(permissions, null, null, institution?.id, null, !!institution?.id);

    const showInstitutions = React.useMemo(() => {
        return !parsedInstitutionCode
    }, [parsedInstitutionCode]);

    const institutionsToUse = React.useMemo(() => {
        return institutions?.filter(i => i.code);
    }, [institutions]);

    const onRowClick = React.useCallback((event: React.MouseEvent<Element, MouseEvent>, rowData?: Dtos.IPatient | undefined) => {
        if (canAdministerPathologyTest && !canAdministerOpms) {
            history.push(`/registration/${institutionCode ?? ALL_INSTITUTIONS_CODE}/${rowData?.studyNumber}/pdl-and-til-result/1/pdl-and-til-result-form/1`)
        } else {
            history.push(`/registration/${institutionCode ?? ALL_INSTITUTIONS_CODE}/${rowData?.studyNumber}`)
        }
    }, [history, institutionCode, canAdministerPathologyTest, canAdministerOpms]);

    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);

    const client = onlinePatientManagement.serviceStackClient;

    const [treatments, setTreatments] = React.useState<Dtos.Treatment[]>([]);

    React.useEffect(() => {
        client
            .get(new Dtos.TreatmentGetCollection())
            .then(response => {
                setTreatments(response.treatments);
            })
            .catch((e) => {

            });
    }, [client, setTreatments]);

    const newColumns = useNewPatientColumns(institutions, showInstitutions);
    const preregisteredColumns = usePreregisteredPatientColumns(institutions, showInstitutions);
    const registeredColumns = useRegisteredPatientColumns(institutions, showInstitutions, treatments);
    const ineligibleColumns = useIneligiblePatientColumns(institutions, showInstitutions);

    return (
        <>
            <InstitutionBreadcrumbs />
            <div
                className={classes.container}
            >
                <TrialContextSelector
                    onChange={onSelect}
                    allowAllMasterGroups={true}
                    allowAllCollaboratingGroups={true}
                    allowAllCountries={true}
                    allowAllInstitutions={true}
                    hideMasterGroups={true}
                    hideCollaboratingGroups={true}
                    hideCountries={true}
                    masterGroupCode={masterGroupCode}
                    collaboratingGroupCode={collaboratingGroupCode}
                    countryCode={countryCode}
                    institutionCode={institutionCode}
                    institutions={institutionsToUse}
                >



                    <Grid
                        item
                        xs={12}
                        sm={6}
                        className={classes.search}
                    >
                        <Search/>
                    </Grid>


                    {/*<Grid*/}
                    {/*    item*/}
                    {/*    xs={12}*/}
                    {/*    sm={6}*/}
                    {/*    className={classes.addPatientColumn}*/}
                    {/*>*/}
                    {/*    {*/}
                    {/*    //    parsedInstitutionCode && canRegisterPatient ?*/}
                    {/*    //        institution?.enabled ?*/}
                    {/*    //            <Button*/}
                    {/*    //                className={classes.addPatient}*/}
                    {/*    //                variant="contained"*/}
                    {/*    //                color="secondary"*/}
                    {/*    //                component={Link}*/}
                    {/*    //                to={`/registration/${parsedInstitutionCode ?? ALL_INSTITUTIONS_CODE}/add-participant`}*/}
                    {/*    //            >*/}
                    {/*    //                Add Participant*/}
                    {/*    //            </Button> :*/}
                    {/*    //            <Tooltip*/}
                    {/*    //                className={classes.addPatient}*/}
                    {/*    //                title="Institution must be active to add a participant"*/}
                    {/*    //            >*/}
                    {/*    //                <div>*/}
                    {/*    //                    <Button*/}
                    {/*    //                        className={classes.addPatient}*/}
                    {/*    //                        disabled*/}
                    {/*    //                        variant="contained"*/}
                    {/*    //                        color="secondary"*/}
                    {/*    //                        component={Link}*/}
                    {/*    //                        to={`/registration/${parsedInstitutionCode ?? ALL_INSTITUTIONS_CODE}/add-participant`}*/}
                    {/*    //                    >*/}
                    {/*    //                        Add Participant*/}
                    {/*    //                    </Button>*/}
                    {/*    //                </div>*/}
                    {/*    //            </Tooltip> :*/}
                    {/*    //            <Tooltip*/}
                    {/*    //                className={classes.addPatient}*/}
                    {/*    //                title={canRegisterPatient ? 'Select an institution to add a participant' : 'Insufficient permissions to add a participant'}*/}
                    {/*    //            >*/}
                    {/*    //                <div>*/}
                    {/*    //                    <Button*/}
                    {/*    //                        className={classes.addPatient}*/}
                    {/*    //                        disabled*/}
                    {/*    //                        variant="contained"*/}
                    {/*    //                        color="secondary"*/}
                    {/*    //                        component={Link}*/}
                    {/*    //                        to={`/registration/${parsedInstitutionCode ?? ALL_INSTITUTIONS_CODE}/add-participant`}*/}
                    {/*    //                    >*/}
                    {/*    //                        Add Participant*/}
                    {/*    //                    </Button>*/}
                    {/*    //                </div>*/}
                    {/*    //            </Tooltip>*/}
                    {/*    }*/}
                    {/*</Grid>*/}
                </TrialContextSelector>

                <PatientsByCodesResolver
                    masterGroupCode={null}
                    collaboratingGroupCode={null}
                    countryCode={null}
                    institutionCode={parsedInstitutionCode}
                    patientStateIds={newStates}
                >
                    <PatientTable
                        title="New Participants"
                        entityName="Participant"
                        onRowClick={onRowClick}
                        columns={newColumns}
                        centralLab={canAdministerPathologyTest && !canAdministerOpms}
                    />
                </PatientsByCodesResolver>
                <PatientsByCodesResolver
                    masterGroupCode={null}
                    collaboratingGroupCode={null}
                    countryCode={null}
                    institutionCode={parsedInstitutionCode}
                    patientStateIds={preregisteredStates}
                >
                    <PatientTable
                        title="Pre-registered Participants"
                        entityName="Participant"
                        onRowClick={onRowClick}
                        columns={preregisteredColumns}
                        centralLab={canAdministerPathologyTest && !canAdministerOpms}
                    />
                </PatientsByCodesResolver>
                <PatientsByCodesResolver
                    masterGroupCode={null}
                    collaboratingGroupCode={null}
                    countryCode={null}
                    institutionCode={parsedInstitutionCode}
                    patientStateIds={registeredStates}
                >
                    <PatientTable
                        title="Registered Participants"
                        entityName="Participant"
                        onRowClick={onRowClick}
                        columns={registeredColumns}
                        centralLab={canAdministerPathologyTest && !canAdministerOpms}
                    />
                </PatientsByCodesResolver>
                <PatientsByCodesResolver
                    masterGroupCode={null}
                    collaboratingGroupCode={null}
                    countryCode={null}
                    institutionCode={parsedInstitutionCode}
                    patientStateIds={ineligibleStates}
                >
                    <PatientTable
                        title="Ineligible Participants"
                        entityName="Participant"
                        onRowClick={onRowClick}
                        columns={ineligibleColumns}
                        centralLab={canAdministerPathologyTest && !canAdministerOpms}
                    />
                </PatientsByCodesResolver>
            </div>
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default Registration;
