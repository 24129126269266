/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';


/**
 * Used for the basic page layout.
 */
import {
    Checkbox,
    CollaboratingGroupContext,
    CrfCondition,
    CrfForm,
    Field,
    FieldGroup,
    FormBreadcrumbs,
    FormGrid,
    GetFieldLookupItem,
    ICrfConditionParameters,
    IFormGridCell,
    Input,
    InstitutionContext,
    KeyboardDatePicker,
    MasterGroupContext,
    Numeric,
    PatientContext,
    RouteLoading,
    Select,
    Text,
    getParentPropertyPath
} from '@ngt/opms';

import { usePermissionsByIds } from '@ngt/opms-bctapi';

import { RequestState } from '@ngt/request-utilities';

import { InputAdornment, Typography, makeStyles } from '@material-ui/core';


/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import { get } from 'lodash-es';
import * as Dtos from '../../api/dtos';
import SourceDocuments from '../../components/SourceDocuments';
import { THREE_DIGIT_POSITIVE_INTEGER, TWO_DIGIT_POSITIVE_INTEGER } from './General';
import { THREE_DIGIT_POSITIVE_INTEGER_ONE_DECIMAL_PLACE } from './LaboratoryAssessments';


/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IPathologyAndPriorTreatmentProps {
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    subHeading: {
        padding: theme.spacing(3, 3, 3, 3)
    },
    instruction: {
        padding: theme.spacing(0,3)
    },
    gridNote: {
        margin: theme.spacing(-7, 3, 4)
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

const isTherapyTypeOther = ({ lookups, formState, parentFieldName }: ICrfConditionParameters<Dtos.PathologyAndPriorTreatment>) => {
    // parent field name will be the cell in the grid, we want to get the row, so get the parent of the parent.
    const parentPropertyPath = getParentPropertyPath(parentFieldName);

    if (!parentPropertyPath) {
        return false;
    }

    const row: Dtos.PreviousTreatment = get(formState?.values, parentPropertyPath);

    if (!row) {
        return false;
    }

    return row.therapyType === Dtos.LookupTherapyType.Taxane as number || 
        row.therapyType === Dtos.LookupTherapyType.ADC as number ||
        row.therapyType === Dtos.LookupTherapyType.Other as number;
}

const isReasonOther = ({ lookups, formState, parentFieldName }: ICrfConditionParameters<Dtos.PathologyAndPriorTreatment>) => {
    // parent field name will be the cell in the grid, we want to get the row, so get the parent of the parent.
    const parentPropertyPath = getParentPropertyPath(parentFieldName);

    if (!parentPropertyPath) {
        return false;
    }

    const row: Dtos.PreviousTreatment = get(formState?.values, parentPropertyPath);

    if (!row) {
        return false;
    }

    return row.discontinuationReason === Dtos.LookupDiscontinuationReasonType.Other as number;
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const previousTreatmentsColumns: Array<IFormGridCell<Dtos.PreviousTreatment>> = [
    {
        name: 'therapyType',
        minWidth: 100,
        maxWidth: 100,
        width: 100,
        content: (
            <Input
                component={Select}
                style={{ width: '96px' }}
            />
        )
    },
    {
        name: 'therapyTypeSpecify',
        minWidth: 145,
        maxWidth: 145,
        width: 145,
        content: (
            <CrfCondition
                type={Dtos.PathologyAndPriorTreatment}
                condition={isTherapyTypeOther}
                mode="Enable"
                subscription={{ values: true }}
            >
                <Input
                    component={Text}
                />
            </CrfCondition>
        )
    },
    {
        name: 'diseaseSetting',
        minWidth: 90,
        maxWidth: 90,
        width: 90,
        content: (
            <Input
                component={Select}
                style={{ width: '90px' }}
            />
        )
    },
    {
        name: 'startDate',
        minWidth: 205,
        maxWidth: 205,
        width: 205,
        content: (
            <Input
                component={KeyboardDatePicker}
            />
        )
    },
    {
        name: 'endDate',
        minWidth: 205,
        maxWidth: 205,
        width: 205,
        content: (
            <Input
                component={KeyboardDatePicker}
            />
        )
    },
    {
        name: 'discontinuationReason',
        minWidth: 90,
        maxWidth: 90,
        width: 90,
        content: (
            <Input
                component={Select}
                style={{ width: '90px' }}
            />
        )
    },
    {
        name: 'discontinuationReasonSpecify',
        minWidth: 145,
        maxWidth: 145,
        width: 145,
        content: (
            <CrfCondition
                type={Dtos.PathologyAndPriorTreatment}
                condition={isReasonOther}
                mode="Enable"
                subscription={{ values: true }}
            >
                <Input
                    component={Text}
                />
            </CrfCondition>
        )
    },
];


/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Dtos.Permission[] = [
    Dtos.Permission.OpmsCrfUpdate,
    Dtos.Permission.OpmsAdminister,
];

const PreviousTreatmentsTbl: React.FunctionComponent = () => {

    return (
        <>
            <FormGrid
                type={Dtos.PreviousTreatment}
                name="previousTreatments"
                columns={previousTreatmentsColumns}
                rowLabel="Previous Treatment"
                getRowValue={(getFieldValue: (path: string) => string, lookups, parentName: string, index?: number) => {
                    if (parentName && index !== undefined && lookups) {
                        var therapyType = getFieldValue(`${parentName}[${index}].therapyType`);
                        var therapyTypeSpecify = getFieldValue(`${parentName}[${index}].therapyTypeSpecify`);
                        var rowValue = GetFieldLookupItem(lookups, 'PreviousTreatments.TherapyType', +therapyType)?.value;

                        if (therapyTypeSpecify) {
                            rowValue = rowValue?.replace("Specify", therapyTypeSpecify);
                        }

                        return rowValue;
                    }

                    return undefined;
                }}
                gridNote={
                    <Typography>
                        Please add as many rows as required to report all prior lines of anti-HER2 therapy.
                    </Typography>
                }
            />
        </>
    );
}

const isErbb2Enabled = ({ lookups, formState }: ICrfConditionParameters<Dtos.PathologyAndPriorTreatment>) => !formState?.values.erbb2CentromeresNotDone;
const isHer2CopyNumberEnabled = ({ lookups, formState }: ICrfConditionParameters<Dtos.PathologyAndPriorTreatment>) => !formState?.values.her2CopyNumberNotDone;

const PathologyAndPriorTreatment: React.FunctionComponent<IPathologyAndPriorTreatmentProps> = () => {
    const classes = useStyles();

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);

    const [[canUpdateCrf, ], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, true, false);

    if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }

    return (
        <>
            <FormBreadcrumbs />
            <CrfForm
                formType={Dtos.PathologyAndPriorTreatment}
                validateOn="onChange"
                canEdit={canUpdateCrf}
            >
                <Field
                    name="dateOfHistopathologicalDiagnosis"
                    component={KeyboardDatePicker}
                />
                <FieldGroup>
                    <Typography
                        variant="h3"
                        color="primary"
                        className={classes.subHeading}
                    >
                        Receptor Status (Oestrogen Receptor)
                    </Typography>
                    <Field
                        name="oestrogenSample"
                        component={Select}
                    />
                    <Field
                        name="oestrogenSampleCollectionDate"
                        component={KeyboardDatePicker}
                    />
                    <Field
                        name="oestrogenPercentageOfCellsStained"
                        component={Select}
                    />
                    <Field
                        name="oestrogenPercentageOfCellsStainedValue"
                        component={Numeric}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    %&nbsp;
                                </InputAdornment>
                            )
                        }}
                        numberFormat={TWO_DIGIT_POSITIVE_INTEGER}
                    />
                </FieldGroup>
                <FieldGroup>
                    <Typography
                        variant="h3"
                        color="primary"
                        className={classes.subHeading}
                    >
                        Receptor Status (Progesterone Receptor)
                    </Typography>
                    <Field
                        name="progesteroneSample"
                        component={Select}
                    />
                    <Field
                        name="progesteroneSampleCollectionDate"
                        component={KeyboardDatePicker}
                    />
                    <Field
                        name="progesteronePercentageOfCellsStained"
                        component={Select}
                    />
                    <Field
                        name="progesteronePercentageOfCellsStainedValue"
                        component={Numeric}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    %&nbsp;
                                </InputAdornment>
                            )
                        }}
                        numberFormat={TWO_DIGIT_POSITIVE_INTEGER}
                    />
                </FieldGroup>
                <FieldGroup>
                    <Typography
                        variant="h3"
                        color="primary"
                        className={classes.subHeading}
                    >
                        Receptor Status (HER2 Expression)
                    </Typography>
                    <Field
                        name="her2Sample"
                        component={Select}
                    />
                    <Field
                        name="her2SampleCollectionDate"
                        component={KeyboardDatePicker}
                    />
                    <Field
                        name="her2IhcScore"
                        component={Select}
                    />
                    <Field
                        name="her2Ish"
                        component={Select}
                    />
                    <Field
                        name="erbb2CentromeresNotDone"
                        component={Checkbox}
                        label="ERBB2/centromeres"
                    />
                    <CrfCondition
                        type={Dtos.PathologyAndPriorTreatment}
                        condition={isErbb2Enabled}
                        mode="Enable"
                        subscription={{ values: true }}
                    >
                        <Field
                            name="erbb2Centromeres"
                            component={Numeric}
                            label=""
                            paddingTop={0}
                            numberFormat={THREE_DIGIT_POSITIVE_INTEGER_ONE_DECIMAL_PLACE}
                        />
                    </CrfCondition>
                    <Typography>
                        <div style={{ marginLeft: '30px', marginTop: '10px', fontWeight: 600 }}>OR</div>
                    </Typography>
                    <Field
                        name="her2CopyNumberNotDone"
                        component={Checkbox}
                        label="HER2 copy number"
                    />
                    <CrfCondition
                        type={Dtos.PathologyAndPriorTreatment}
                        condition={isHer2CopyNumberEnabled}
                        mode="Enable"
                        subscription={{ values: true }}
                    >
                        <Field
                            name="her2CopyNumber"
                            component={Numeric}
                            label=""
                            paddingTop={0}
                            numberFormat={THREE_DIGIT_POSITIVE_INTEGER}
                        />
                    </CrfCondition>
                </FieldGroup>
                <FieldGroup>
                    <Typography
                        variant="h3"
                        color="primary"
                        className={classes.subHeading}
                    >
                        BRCA Germline Status
                    </Typography>
                    <Field
                        name="brcaGermlineStatus"
                        component={Select}
                    />
                </FieldGroup>

                <FieldGroup>
                    <Typography
                        variant="h3"
                        color="primary"
                        className={classes.subHeading}
                    >
                        Previous Treatments
                    </Typography>
                    <Field
                        name="patientProgressOrIntolerant"
                        component={Select}
                    />

                    <PreviousTreatmentsTbl />
                </FieldGroup>

                <SourceDocuments />
            </CrfForm>
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default PathologyAndPriorTreatment;
