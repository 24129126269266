/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';


/**
 * Used for the basic page layout.
 */
import {
    CollaboratingGroupContext,
    CrfCondition,
    CrfForm,
    CtcaeSelector,
    Field,
    FieldGroup,
    FormBreadcrumbs,
    FormGrid,
    GetFieldLookupItem,
    ICrfConditionParameters,
    IFormGridCell,
    Input,
    InstitutionContext,
    Masked,
    MasterGroupContext,
    PatientContext,
    RouteLoading,
    Select,
    Text,
    ToggleButtonGroup,
    getParentPropertyPath
} from '@ngt/opms';

import { usePermissionsByIds } from '@ngt/opms-bctapi';

import { RequestState } from '@ngt/request-utilities';

import { Typography, makeStyles } from '@material-ui/core';


/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import { JsonServiceClient } from '@servicestack/client';
import { get } from 'lodash-es';
import * as Dtos from '../../api/dtos';
import SourceDocuments from '../../components/SourceDocuments';


/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IMedicalHistoryAndConcomitantMedicationsProps {
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    subHeading: {
        padding: theme.spacing(3, 3, 3, 3)
    },
    instruction: {
        padding: theme.spacing(0, 3, 0),
        fontSize: '1rem'
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

const ongoingUseOfSystemicCorticosteroids = ({ lookups, formState }: ICrfConditionParameters<Dtos.MedicalHistoryAndConMeds>) => GetFieldLookupItem(lookups, 'ongoingSystemicCorticosteroids', formState?.values.ongoingSystemicCorticosteroids)?.type === Dtos.LookupYesNoType.Yes;
const isTotalDailyDoseLessThanOrEqualTwo = ({ lookups, formState }: ICrfConditionParameters<Dtos.MedicalHistoryAndConMeds>) => GetFieldLookupItem(lookups, 'totalDailyDose', formState?.values.totalDailyDose)?.type === Dtos.LookupDailyDoseType.LessThanOrEqualTwo;

// COMMENTED OUT FOR BCTTUG-148, Incase they change their mind.

//const isDoseNotUnknown = ({ lookups, formState, parentFieldName }: ICrfConditionParameters<Dtos.ConcomitantMedication>) => {
//    // parent field name will be the cell in the grid, we want to get the row, so get the parent of the parent.
//    const parentPropertyPath = getParentPropertyPath(parentFieldName);

//    if (!parentPropertyPath) {
//        return false;
//    }

//    const row: Dtos.ConcomitantMedication = get(formState?.values, parentPropertyPath);

//    if (!row) {
//        return false;
//    }

//    return row.dose !== 'UK';
//};

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

export const UK_DATE = {
    mask: [/[0-3U]/, /[0-9K]/, '/', /[0-1U]/, /[0-9K]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
    showMask: true
};

export const UK_DOSE = {
    mask: (
        (value: string) => {
            if (value?.charAt(0) === 'U') {
                return [/U/, /K/]
            } else {
                var decimalIndex = value?.indexOf('.');
                var wholeNumber = decimalIndex > 0 ? value?.substr(0, decimalIndex) : value;
                var regexArr: Array<RegExp> = [];

                if (wholeNumber?.length > 0) {
                    Object.assign([], wholeNumber).forEach((num, index) => regexArr.push(/[0-9]/));

                    if (decimalIndex > 0) {
                        regexArr = [...regexArr, /\./, /[0-9]/, /[0-9]/];
                    }
                }

                return regexArr;
            }
        }
    ),
    showMask: false,
    guide: false
};

const otherMedicalConditionsColumns: Array<IFormGridCell<Dtos.OtherMedicalCondition>> = [
    {
        name: 'medicalCondition',
        minWidth: 250,
        maxWidth: 250,
        width: 250,
        content: (
            <Input
                component={Text}
            />
        )
    },
    {
        name: 'currentAtRegistration',
        minWidth: 200,
        maxWidth: 200,
        width: 200,
        content: (
            <Input
                component={ToggleButtonGroup}
            />
        )
    },
    {
        name: 'dateOfDiagnosis',
        minWidth: 200,
        maxWidth: 200,
        width: 200,
        content: (
            <Input
                component={Masked}
                maskedFormat={UK_DATE}
            />
        )
    },
    {
        name: 'treatmentOngoing',
        minWidth: 200,
        maxWidth: 200,
        width: 200,
        content: (
            <Input
                component={ToggleButtonGroup}
            />
        )
    }
];

const medicalConditionsColumns: Array<IFormGridCell<Dtos.MedicalCondition>> = [
    {
        name: 'ctcaeTermId',
        minWidth: 450,
        maxWidth: 450,
        width: 450,
        content: (
            <Input
                component={CtcaeSelector}
                gradeFieldName="ctcaeGradeId"
                specificationFieldName="ctcaeSpecification"
                display="SelectorWithName"
                ctcaeUrl="https://ctcae.breastcancertrials.org.au/"
            />
        )
    },
    {
        name: 'currentAtRegistration',
        minWidth: 200,
        maxWidth: 200,
        width: 200,
        content: (
            <Input
                component={ToggleButtonGroup}
            />
        )
    },
    {
        name: 'dateOfDiagnosis',
        minWidth: 200,
        maxWidth: 200,
        width: 200,
        content: (
            <Input
                component={Masked}
                maskedFormat={UK_DATE}
            />
        )
    },
    {
        name: 'treatmentOngoing',
        minWidth: 200,
        maxWidth: 200,
        width: 200,
        content: (
            <Input
                component={ToggleButtonGroup}
            />
        )
    }
];

const concomitantMedicationsColumns: Array<IFormGridCell<Dtos.ConcomitantMedication>> = [
    {
        name: 'genericMedicationName',
        minWidth: 160,
        maxWidth: 160,
        width: 160,
        content: (
            <Input
                component={Text}
            />
        )
    },

    // COMMENTED OUT FOR BCTTUG-148, Incase they change their mind.
    //{
    //    name: 'dose',
    //    minWidth: 100,
    //    maxWidth: 100,
    //    width: 100,
    //    content: (
    //        <Input
    //            component={Masked}
    //            maskedFormat={UK_DOSE}
    //        />
    //    )
    //},
    //{
    //    name: 'unitsOfMeasure',
    //    minWidth: 180,
    //    maxWidth: 180,
    //    width: 180,
    //    content: (
    //        <CrfCondition
    //            type={Dtos.ConcomitantMedication}
    //            condition={isDoseNotUnknown}
    //            mode="Enable"
    //            subscription={{ values: true }}
    //        >
    //            <Input
    //                component={Select}
    //            />
    //        </CrfCondition>
    //    )
    //},
    //{
    //    name: 'frequency',
    //    minWidth: 180,
    //    maxWidth: 180,
    //    width: 180,
    //    content: (
    //        <Input
    //            component={Select}
    //        />
    //    )
    //},
    //{
    //    name: 'route',
    //    minWidth: 170,
    //    maxWidth: 170,
    //    width: 170,
    //    content: (
    //        <Input
    //            component={Select}
    //        />
    //    )
    //},
    //{
    //    name: 'indication',
    //    minWidth: 170,
    //    maxWidth: 170,
    //    width: 170,
    //    content: (
    //        <Input
    //            component={Select}
    //        />
    //    )
    //},
    {
        name: 'indication',
        minWidth: 160,
        maxWidth: 160,
        width: 160,
        content: (
            <Input
                component={Text}
            />
        )
    },

    {
        name: 'startDate',
        minWidth: 150,
        maxWidth: 150,
        width: 150,
        content: (
            <Input
                component={Masked}
                maskedFormat={UK_DATE}
            />
        )
    },
];

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Dtos.Permission[] = [
    Dtos.Permission.OpmsCrfUpdate,
    Dtos.Permission.OpmsAdminister,
];

const MedicalConditionTbl: React.FunctionComponent = () => {
    const classes = useStyles();

    const serviceClient = React.useMemo(() => {
        const client = new JsonServiceClient("/");

        client.credentials = "omit";

        return client;
    }, []);

    return (
        <>
            <Typography
                variant="h6"
                className={classes.instruction}
            >
                <strong>Instructions:</strong> Please report relevant medical history and ongoing conditions using the NCI CTCAE v5.0 table where possible. For those that cannot be classified by NCI CTCAE (e.g. relevant surgical history) please use the Other Relevant Medical History table.
            </Typography>
            <Typography
                variant="h3"
                color="primary"
                className={classes.subHeading}
            >
                Relevant Medical History & ongoing conditions (NCI CTCAE)
            </Typography>
            <FormGrid
                type={Dtos.MedicalCondition}
                name="medicalConditions"
                columns={medicalConditionsColumns}
                rowLabel="Relevant Medical History/Ongoing Condition"
                getRowValue={(getFieldValue: (path: string) => string, lookups, parentName: string, index?: number) => {
                    if (parentName && index !== undefined && lookups) {
                        var rowValue = getFieldValue(`${parentName}[${index}].ctcaeTermId`);

                        if (rowValue) {
                            const request = new Dtos.GetCtcaeTermName({ ctcaeTermId: +rowValue });

                            var response = serviceClient
                                .get(request)
                                .then(response => {
                                    return response.ctcaeTermName
                                })
                                .catch(error => {
                                    return undefined;
                                })

                            return response;
                        }
                    }

                    return undefined;
                }}
            />
        </>
    );
}

const OtherMedicalConditionTbl: React.FunctionComponent = () => {
    const classes = useStyles();

    return (
        <>
            <Typography
                variant="h3"
                color="primary"
                className={classes.subHeading}
                style={{ paddingTop: 0 }}
            >
                Other Relevant Medical History
            </Typography>
            <FormGrid
                type={Dtos.OtherMedicalCondition}
                name="otherMedicalConditions"
                columns={otherMedicalConditionsColumns}
                rowLabel="Medical Condition"
                rowValueAccessor="medicalCondition"
            />
        </>
    );
}

const ConcomitantMedicationTbl: React.FunctionComponent = () => {
    const classes = useStyles();

    return (
        <>
            <Typography
                variant="h3"
                color="secondary"
                className={classes.subHeading}
            >
                CONCOMITANT MEDICATIONS
            </Typography>
            <FormGrid
                type={Dtos.ConcomitantMedication}
                name="concomitantMedications"
                columns={concomitantMedicationsColumns}
            />
        </>
    );
}

const MedicalHistoryAndConcomitantMedications: React.FunctionComponent<IMedicalHistoryAndConcomitantMedicationsProps> = () => {
    const classes = useStyles();

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);

    const [[canUpdateCrf, ], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, true, false);

    if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }

    return (
        <>
            <FormBreadcrumbs />
            <CrfForm
                formType={Dtos.MedicalHistoryAndConMeds}
                validateOn="onChange"
                canEdit={canUpdateCrf}
            >
                <FieldGroup>
                    <Typography
                        variant="h3"
                        color="secondary"
                        className={classes.subHeading}
                    >
                        MEDICAL HISTORY
                    </Typography>
                    <MedicalConditionTbl />
                    <OtherMedicalConditionTbl />
                </FieldGroup>

                <Field
                    name="anyRelatedToxicity"
                    component={ToggleButtonGroup}
                />

                <Field
                    name="activeInfection"
                    component={ToggleButtonGroup}
                />

                <FieldGroup>
                    <ConcomitantMedicationTbl />
                </FieldGroup>

                <Typography
                    variant="h3"
                    color="primary"
                    className={classes.subHeading}
                >
                    Prohibited Concomitant Medications
                </Typography>

                <Field
                    name="receivedTreatment"
                    component={ToggleButtonGroup}
                />

                <FieldGroup>
                    <Field
                        name="ongoingSystemicCorticosteroids"
                        component={ToggleButtonGroup}
                    />
                    <CrfCondition
                        type={Dtos.MedicalHistoryAndConMeds}
                        condition={ongoingUseOfSystemicCorticosteroids}
                        mode="Enable"
                        subscription={{ values: true }}
                    >
                        <Field
                            name="totalDailyDose"
                            component={Select}
                        />
                    </CrfCondition>
                    <CrfCondition
                        type={Dtos.MedicalHistoryAndConMeds}
                        condition={isTotalDailyDoseLessThanOrEqualTwo}
                        mode="Enable"
                        subscription={{ values: true }}
                    >
                        <Field
                            name="approvalForChronicStableDose"
                            component={ToggleButtonGroup}
                        />
                    </CrfCondition>
                </FieldGroup>


                <Field
                    name="useOfStrongCyp2c8"
                    component={ToggleButtonGroup}
                />

                <Field
                    name="treatmentWithBotanicalPreparations"
                    component={ToggleButtonGroup}
                />

                <Field
                    name="liveAttenuatedVaccine"
                    component={ToggleButtonGroup}
                />

                <Field
                    name="haveContraceptivesStopped"
                    component={ToggleButtonGroup}
                />

                <SourceDocuments />
            </CrfForm>
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default MedicalHistoryAndConcomitantMedications;
