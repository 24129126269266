/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the sae landing page component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { useHistory, useParams } from 'react-router';

import { Grid, TextField, Theme, makeStyles } from '@material-ui/core';

import { ISaeSearchContext, SaeBreadcrumbs, SaeCollapsibleTable, SaeSearchContext, SaeStatus, useSaes } from '@ngt/opms-sae';

import { usePermissionsByIds } from '@ngt/opms-bctapi';

import { RequestState } from '@ngt/request-utilities';

import {
    ALL_INSTITUTIONS_CODE,
    InstitutionsContext,
    TrialContextSelector
} from '@ngt/opms';

import { debounce } from "lodash";


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../../api/dtos';

/*
 * used to get column spec for the sae table.
 */
import useSaeColumns from '../../hooks/sae/useSaeColumns';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface ISaesPageParams {
    institutionCode?: string;
}

interface ISaesPageProps {
}


/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    ml1: {
        marginLeft: theme.spacing(1),
    },
    mt1: {
        marginTop: theme.spacing(1),
    },
    mt3: {
        marginTop: theme.spacing(3)
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const permissions: Dtos.Permission[] = [
    Dtos.Permission.SaeView,
    Dtos.Permission.SaeReview,
    Dtos.Permission.SaeAdminister,
    Dtos.Permission.MedicalReviewPerform
];

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

const SaeSearch = () => {
    const { setPatientStudyNumber, setSaeNumber } = React.useContext<ISaeSearchContext>(SaeSearchContext);

    const debouncedSetPatientStudyNumber = React.useRef(debounce(value => setPatientStudyNumber(value), 500));

    const debouncedSetSaeNumber = React.useRef(debounce(value => setSaeNumber(value), 500));

    const patientStudyNumberOnChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        debouncedSetPatientStudyNumber.current(event.target.value);
    }, [debouncedSetPatientStudyNumber]);

    const saeNumberOnChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        event.target.value = event.target.value.replace(/[^0-9]/g, '');

        debouncedSetSaeNumber.current(event.target.value);
    }, [debouncedSetSaeNumber]);

    return (
        <>
            <Grid
                item
                xs={12}
                sm={4}
                style={{ display: 'flex', alignItems: 'end' }}
            >
                <TextField
                    label="Patient"
                    variant="standard"
                    onChange={patientStudyNumberOnChange}
                    fullWidth
                />
            </Grid>
            <Grid
                item
                xs={12}
                sm={4}
                style={{ display: 'flex', alignItems: 'end' }}
            >
                <TextField
                    label="SAE Number"
                    variant="standard"
                    onChange={saeNumberOnChange}
                    fullWidth
                />
            </Grid>
        </>
    );
}

const SaesPage: React.FunctionComponent<ISaesPageProps> = () => {
    const classes = useStyles();

    const history = useHistory();

    const { institutionCode } = useParams<Record<string, string>>();

    const contextInstitutions = React.useContext(InstitutionsContext);

    const institutions = contextInstitutions?.institutions;

    useSaes(institutionCode, true);

    const institution = React.useMemo(() => {
        return institutions?.find(i => i.code === institutionCode);
    }, [institutions, institutionCode]);

    const [[canViewSae, /* canReviewSae */, canAdministerSae, /*canMedicallyReviewSae */], permissionLoadState] = usePermissionsByIds(permissions, null, null, institution?.id, null, institution ? true : false);

    const onSelect = React.useCallback((newMasterGroupCode?: string | null, newCollaboratingGroupCode?: string | null, newCountryCode?: string | null, newInstitutionCode?: string | null) => {
        if (newInstitutionCode) {
            history?.push(`/sae/${newInstitutionCode}`);
        }
        else {
            history?.push(`/sae`);
        }

        return;
    }, [history]);

    const showInstitutions = React.useMemo(() => {
        return !institutionCode
    }, [institutionCode]);

    const saeDataColumns = useSaeColumns(contextInstitutions.institutions, showInstitutions) as any;

    const invalidSaeDataColumns = useSaeColumns(contextInstitutions.institutions, showInstitutions, true) as any;

    React.useEffect(() => {
        if (permissionLoadState.state !== RequestState.Pending &&
            permissionLoadState.state !== RequestState.None) {
            if (!canViewSae) {
                history.replace(`/error/403`);
            }
        }
    }, [institutions, canViewSae, canAdministerSae, permissionLoadState, history]);

    const [patientStudyNumber, setPatientStudyNumber] = React.useState<string | undefined>(undefined);

    const [saeNumber, setSaeNumber] = React.useState<string | undefined>(undefined);

    const saeSearchContext: ISaeSearchContext = React.useMemo(() => {
        return {
            patientStudyNumber,
            setPatientStudyNumber: (patientStudyNumber) => setPatientStudyNumber(patientStudyNumber),
            saeNumber,
            setSaeNumber: (saeNumber) => setSaeNumber(saeNumber)
        }

    }, [patientStudyNumber, setPatientStudyNumber, saeNumber, setSaeNumber]);

    return (
        <>
            <SaeBreadcrumbs institution={institution} />

            <div
                className={classes.container}
            >
                <SaeSearchContext.Provider value={saeSearchContext}>
                    <TrialContextSelector
                        onChange={onSelect}
                        allowAllMasterGroups={false}
                        allowAllCollaboratingGroups={false}
                        allowAllCountries={false}
                        allowAllInstitutions={true}
                        hideMasterGroups={true}
                        hideCollaboratingGroups={true}
                        hideCountries={true}
                        masterGroupCode={null}
                        collaboratingGroupCode={null}
                        countryCode={null}
                        institutionCode={institution?.code ?? ALL_INSTITUTIONS_CODE}
                        sm={4}
                    >
                        <SaeSearch />
                    </TrialContextSelector>

                    {
                        !!canAdministerSae &&
                        <SaeCollapsibleTable
                            columns={saeDataColumns}
                            status={[SaeStatus.New, SaeStatus.Added]}
                            title={"New SAEs"}
                            pluralizeTitle={false}
                            disabledTableText={"There are no new SAEs"}
                            showInvestigatorColumn
                            showMedicalReviewColumn
                        />
                    }

                    <SaeCollapsibleTable
                        key={`${(institution?.code ?? ALL_INSTITUTIONS_CODE)}-awaiting`}
                        columns={saeDataColumns}
                        status={[SaeStatus.AwaitingForReview]}
                        title={"Awaiting review SAEs"}
                        pluralizeTitle={false}
                        disabledTableText={"There are no awaiting for review SAEs"}
                        showInvestigatorColumn
                        showMedicalReviewColumn
                    />

                    <SaeCollapsibleTable
                        key={`${(institution?.code ?? ALL_INSTITUTIONS_CODE)}-approved`}
                        columns={saeDataColumns}
                        status={[SaeStatus.Approved]}
                        title={"Approved SAEs"}
                        pluralizeTitle={false}
                        disabledTableText={"There are no approved SAEs"}
                        showInvestigatorColumn={false}
                        showMedicalReviewColumn
                    />

                    <SaeCollapsibleTable
                        key={`${(institution?.code ?? ALL_INSTITUTIONS_CODE)}-rejected`}
                        columns={saeDataColumns}
                        status={[SaeStatus.Rejected]}
                        title={"Rejected SAEs"}
                        pluralizeTitle={false}
                        disabledTableText={"There are no rejected SAEs"}
                        showInvestigatorColumn={false}
                        showMedicalReviewColumn
                    />

                    <SaeCollapsibleTable
                        key={`${(institution?.code ?? ALL_INSTITUTIONS_CODE)}-invalid`}
                        columns={invalidSaeDataColumns}
                        status={[SaeStatus.Invalid]}
                        title={"Invalid SAEs"}
                        pluralizeTitle={false}
                        disabledTableText={"There are no invalid SAEs"}
                        showInvestigatorColumn={false}
                        showMedicalReviewColumn={false}
                    />
                </SaeSearchContext.Provider>
            </div>



            {
                // <SaePage institution={institution} />
                //<br /> <br />
                //{JSON.stringify(contextInstitutions?.institutions, null, '\t\n')}
                //<br /> <br />
                //{JSON.stringify(contextInstitutions?.institutions?.find(i => i.code === institutionCode), null, '\n')}
                //<br /><br />
                //{JSON.stringify(institution, null, '\n')}
            }

            {
                //  Permissions
                //  {JSON.stringify(permissionLoadState.state, null, '\n')}
                //  {JSON.stringify(canViewSae, null, '\n')}
            }
        </>
    );
}


/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */
export default SaesPage;